@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
html{
  overflow-y: auto !important;
}

body {
  margin: 0;
}

::placeholder { /* Firefox, Chrome, Opera */ 
  color: gray !important;
  opacity: 0.5 !important;
} 

:-ms-input-placeholder { /* Internet Explorer 10-11 */ 
  color: gray !important;
  opacity: 0.5 !important;
} 

::-ms-input-placeholder { /* Microsoft Edge */ 
  color: gray !important;
  opacity: 0.5 !important;
}

.App{
    font-family: 'Nunito','Open Sans', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
    -webkit-text-stroke: 0px !important;
    -webkit-text-emphasis: none;
            text-emphasis: none;
    font-feature-settings: "kern" 1;
    text-rendering: optimizeLegibility;

}
.custom-alert{
    position: fixed;
    background-color: darkgreen;
    color: white;
    width: 100%;
    z-index: 0;
    text-align: center;
    text-transform: uppercase;
    transform: translateY(-100%);
    transition: transform 0.5s ease-in-out;
}

.show-alert{
    transform: translateY(0);
}
.custom-button{
    position: relative;
}

.custom-button button{
    width: 100%;
    background-color: white !important;
    border: none !important;
    outline: none !important;
    color: #373E4A !important;
    letter-spacing: 2px !important;
    border-radius: 30px;
    padding: 7px 10px;
    font-weight: bold;
    font-size: 16px;
    box-shadow: 2px 2px 2px orange;
}

.custom-button button:focus{
    box-shadow: none !important;
}

.custom-button button:active{
    box-shadow: 0 1px 0  white !important;
    top: 1px !important;
}

.custom-button span{
    position: absolute;
    float: right;
    text-align: right;
    right: 1%;
    top: 4px;
    border-radius: 50%;
    color: white;
    padding: 2px 11px;
    background: #FAA10D;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #FAA10D, #FB6558); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    font-size: 16px;
    pointer-events: none;
}

@media screen and (max-width: 767px){
    .custom-button span{
        right: 3%;
    }
}
.login_logo{
    text-align: center;
    height: 63px;
    width: 150px;
}

.login_page{
    position: fixed !important;
    min-width: 100% !important;
    min-height: 100% !important;
    z-index: -10;
    background: #fc4a1a;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #f7b733, #fc4a1a); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.login_page_main{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20vh;
}

.text-content{
    color: white;
    text-align: center;
}

.text-content div:nth-child(1){
    font-size: x-large;
    font-weight: 400;
}

.text-content div:nth-child(2){
    opacity: 0.6;
}
.custom-input-container{
    position: relative;
}

.custom-input-container input {
    width: 100%;
    padding: 8px 2rem;
    border-radius: 30px;
    border: 2px solid white;
    outline: none;
    color: gray;
}

.custom-input-container span:nth-child(1) {
    position: absolute;
    z-index: 2;
    display: block;
    width: 20px;
    height: 20px;
    margin-top: 0.61rem;
    margin-left: 0.5rem;
    text-align: center;
    pointer-events: none;
    color: lightgray;
}

.custom-input-container span:nth-child(2) {
    position: absolute;
    z-index: 2;
    display: block;
    width: 30px;
    height: 25px;
    margin-top: 0.61rem;
    margin-left: 2rem;
    text-align: center;
    pointer-events: none;
    color: gray;
    opacity: 0.5;
}

.validate-input-icon{
    position: absolute;
    right: 30px;
    top: 11px;
    font-size: 20px;
}
.splash_page{
    position: fixed !important;
    min-width: 100% !important;
    min-height: 100% !important;
    z-index: -10;
    top: 0;
    left: 0;
    background: #fc4a1a;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #f7b733, #fc4a1a); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.splash-screen-main{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.splash-logo{
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.splash-logo img{
  width: auto;
  height: 100%;
  object-fit: cover;
}

.splash-loader{
  font-size: 32px;
  color: white;
  margin-top: 3rem;
}
.account_page{
    position: fixed !important;
    min-width: 100% !important;
    min-height: 100% !important;
    z-index: -10;
    background: #fc4a1a;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #f7b733, #fc4a1a); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.account_page_main{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3vh;
}

.or_divider{
    text-align: center;
    margin: 3px 0;
    opacity: 0.6;
    color: white;
}

.has-search2 .form-control {
    padding-left: 2.375rem;
}

.has-search2 .form-control-feedback2 {
    position: absolute;
    z-index: 2;
    display: block;
    width: 20px;
    height: 20px;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    text-align: center;
    pointer-events: none;
    color: lightgray;
}

.custom_fb_button{
    background-color:#227bef;
    text-transform: uppercase;
    border: none;
    outline: none;
    color: white;
    padding: 7px 10px;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    border-radius: 30px;
    letter-spacing: 2px;
}

.custom_fb_button:hover{
    background-color: #227bef !important;
}

.custom_fb_button:focus{
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.custom_fb_button:active{
    box-shadow: 0 1px 0 blue !important;
    top: 1px !important;
}

.custom_fb_button_container{
    position: relative;
}

.fa-facebook{
    position: absolute;
    float: left;
    font-size: 26px;
    color: white;
    right: 1.2%;
    top: 5px;
}

.loading_spinner{
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
}

.loading_spinner span img{
    width: 40px;
    height: 40px;
}

@media screen and (max-width: 576px){
    .fa-facebook{
        right: 4%;
    }
}

.main-home-container{
    display: flex;
    min-height: 100vh;
}
.home-page{
    margin-top: 5rem;
}
.contest-cards{
    max-width: 1200px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 1.5rem;
}
@media screen and (max-width: 576px){
    .home-page{
        margin-top: 4rem;
        padding-left: 20px;
        padding-right: 20px;
    }
    .contest-cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
    }
}
.img-card {
  width: 100%;
  height:140px;
  display:block;
  overflow: hidden;
  pointer-events: none;
}

.img-card img{
  width: 100%;
  height: 140px;
  object-fit:contain; 
  pointer-events: none;
}
.disable-click-card{
  pointer-events: none;
}

.custom-card{
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12); 
  padding: 5px;
  height: 20rem;
  border-radius: 5px;
  transition: box-shadow 0.3s;
  cursor: pointer;
  position: relative;
}

.custom-card:hover{
  box-shadow: 0 2px 5px 3px rgba(0,0,0,0.16),0 2px 10px 3px rgba(0,0,0,0.12); 
}

.custom-card-title{
  color: #373e4a;
  padding: 1px 5px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0px;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-card-win-score{
  padding: 0px 5px;
  display: flex;
  justify-content: center;
  margin-top: 0px;
  font-size: 14px;
  color:orange;
  font-weight: bold;
  pointer-events: none;
}

.custom-card-score{
  padding: 0px 5px;
  display: flex;
  justify-content: center;
  margin-top: 0px;
  font-size: 16px;
  color: black;
  font-weight: bold;
  pointer-events: none;
}

.custom-card-counter{
  display: flex;
  justify-content: center;
  margin-top: 10px;
  pointer-events: none;
}

.custom-card-button{
  position: relative;
  margin: 0 5%;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.custom-card-button button{
  padding: 4px 30px;
  font-weight: bold;
  background: #FAA10D;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #FAA10D, #FB6558); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  width: 100%;
  outline: none;
  display: flex;
  justify-content: flex-start;
  border-radius: 30px;
  border: none;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 0px;
  pointer-events: none;
}

.custom-card-button button:focus{
  box-shadow: none;
  outline: none;
  border: none;
}

.custom-card-button button:active{
  top: 1px;
  box-shadow: 0 1px 0 #FAA10D;
}

.custom-card-button span{
  position: absolute;
  float: right;
  text-align: right;
  right: 3%;
  top: 3px;
  border-radius: 50%;
  color: white;
  padding: 1px 10px;
  background: #FA6755;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right,#FA6755, #FA9B13); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  font-size: 16px;
  pointer-events: none;
}

.custom-card-play-button{
  display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    pointer-events: none;
}
.counter-wrapper{
  margin-top: 10px;
  pointer-events: none;
}
.bubble {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top:43px;
  left: 2.2em;
    position: absolute;
    background: linear-gradient(to left, #449fe7, #3dabec, #3db7f0, #43c3f2, #4fcef4);
    color: #FFFFFF;
    font-family: Arial;
    text-align: center;
    width: 57px;
    height: 57px;
    border-radius: 50%;

}
.bubble:after {
    content: '';
    position: absolute;
    display: block;
    z-index: 1;
    border-style: solid;
    border-color: #449fe7 transparent;
    border-width: 12px 4px 0;
    bottom: 6px;
    left: 91%;
    transform: rotate(291deg);
}

.bubble-product1{
  font-size: 0.7rem;
  font-weight: bolder; 
}
.bubble-product2{
  font-size: 0.55rem;
  font-weight: bold;
}
@media screen and (max-width:1200px){
  .bubble{
    left: 52px;
  }
  
}
@media screen and (max-width:800px){
  .bubble{
    left: 72px;
  }
}
@media screen and (max-width:600px){
  .bubble{
    left: 30px;
  }
}
@media screen and (max-width: 576px){
  .custom-card-button button{
    justify-content: center;
    width: 70%;
    font-size: 12px;
  }
  .custom-card{
    width: 47%;
    height: 19rem;
  }
  .custom-card-button span{
    padding: 3px 8px;
    right: 16%;
    font-size: 10px;
  }
 .custom-card-title {
   padding: 0;
   font-size: 13px;
 }
  .bubble{
    left: 10px;
}
}
@media screen and (max-width:385px){
  .bubble{
    left: 7px;
  }
}
@media screen and (max-width:320px){
  .custom-card-title {
    font-size: 12px;
  }
  .bubble{
    top: 50px;
    width: 43px;
    height: 43px;
  }
  .bubble-product1 {
    font-size: 9px;
    font-weight: bolder;
}
.bubble-product2 {
  font-size: 7px;
  font-weight: bold;
}
}
.react-countdown-container{
    border: 1px solid #cacaca;
    display: flex;
    justify-content: center;
    align-items: center;
}
.colon{
    font-size: 20px;
    color: #949494;
    margin-bottom: 5px;
}
.react-countdown-container li {
    display: inline-block;
    font-size: 7px;
    text-align: center;
    padding: 1px 5px;
    width: 40px;
    background-color: white;
    color: #949494;
    font-weight: 600;
    opacity: 1;
    list-style-type: none;
    text-transform: capitalize;
}

.danger-time li{
    background-color: #FF6347 !important;
}

.react-countdown-container li span {
    font-weight: 400px;
    display: block;
    font-size: 15px;
    font-weight: 700;
}
.expired-time{
    width: 200px;
    padding: 5px 10px;
    margin: 0px auto;
    background-color: darkblue;
    color: white;
    letter-spacing: 2px;
}

@media screen and (max-width:576px){
    .react-countdown-container li {
        width: 26px;
        font-size: 5px;
    }
    .counter-wrapper{
        width: 135px;
    }
}

@media screen and (max-width:320px){
    .counter-wrapper {
        width: 120px;
    }
    
}
/*style for banner timer*/
.react-countdown-conatiner-slider-banner{
   
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5%;
    box-shadow:1px 7px 20px -9px hsl(0, 0%, 53%);
    height: 39px;
}
.react-countdown-conatiner-slider-banner li{
    display: inline-block;
    font-size: 0.4em;
    text-align: center;
    border-right: 1px solid;
    padding: 1px 5px;
    background-color: white;
    font-weight: 600;
    opacity: 1;
    list-style-type: none;
    text-transform: capitalize;
}
.react-countdown-conatiner-slider-banner .last-li{
    border-right: none;
}
.react-countdown-conatiner-slider-banner li span{
    display: block;
    font-size: 2.5em;
    color:#333;
    font-weight: bolder;
    
}
.ractangle {
    padding: 0 5px;
    font-size: 12px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: none;
    font-weight: bolder;
    width: 60px;
    height: 40px;
    background: linear-gradient(to left, #449fe7, #3dabec, #3db7f0, #43c3f2, #4fcef4);
    -webkit-clip-path: polygon(0 0, 81% 0, 100% 50%, 81% 100%, 0 100%);
            clip-path: polygon(0 0, 81% 0, 100% 50%, 81% 100%, 0 100%);
}

.custom-play-button {
   
    display: flex;
    justify-content: space-between;
    align-items: center;    
    font-size: 0.87em;
    min-width: 190px;
    height: 43px;
    font-weight: bolder;
    background: #FAA10D;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #FAA10D, #FB6558); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
    border-radius: 30px;
    border: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 0px;
    box-shadow: 5px 10px 15px -8px #888888;
    pointer-events: none;
}
    

.ArrowIcon{
    border-radius: 50%;
    color: white;
    padding-left: 5px;
    background: #FA6755;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #FA6755, #FA9B13); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    font-size: 23px;
    pointer-events: none;
    margin-right: 7px;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}
.play{
    margin-left: 27px;
    pointer-events: none;
}
@media screen and (max-width: 576px){
    .custom-play-button {
        min-width: 140px;
        height: 37px;
        font-size: 0.75rem;
       }
       .play {
        margin-left: 13px;
       }
       .ArrowIcon{
           margin-right: 5px;
           height: 30px;
        width: 30px;
       }

}
@media screen and (max-width:320px){

    .custom-play-button {
        min-width: 130px;
        font-size: 0.65rem;
        
       }
}




.toolbar{
    width: 100%;
    background: #E44D26;
    background: linear-gradient(to right, #EC4E3B, #FA9B13);
    height: 58px;
    position: fixed;
    border: none;
    outline: none;
    box-shadow: none;
    top: 0;
    left: 0;
    z-index: 100;
}
.rounded-header-bottom{
    display: none;
}

.toolbar-toggle-button{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 20px;
    width: 18px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
}

.toolbar-toggle-button:focus{
    outline: none;
}

.toolbar-toogle-button-line{
    width: 18px;
    height: 2px;
    background: white;
}

.toolbar-logo {
    cursor: pointer;
    margin-left: 0.3rem;
}

.toolbar-navigation{
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0.5rem 1rem;
}

.toolbar-space{
    flex: 1 1;
}

.toolbar-navigation-items ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}
.back-button{
    width: 100px;
    height: 35px;
    border-radius: 20px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
}
a {
    color:black;
    text-decoration: none;
    background-color: transparent;
}
a:hover{
    color: black;
    text-decoration: none;
}
.toolbar-navigation-items li{
    padding: 0px 6px;
}
.header-icon{
    color: white;
    margin-right: 0.5rem;
    margin-top: 5px;
    pointer-events: none;
}
@media screen and (max-width: 576px){
    .rounded-header-bottom{
        display: block;
        height: 50px;
        width: 100%;
        outline: none;
        border: none;
        box-shadow: none;
        position: fixed;
        background: #E44D26;
        background: linear-gradient(to right, #EC4E3B, #FA9B13);
        top: 58px;
        border-bottom-left-radius: 600px;
        border-bottom-right-radius: 600px;
    }
    .header-leaderboard{
        height: 380px;
        border-bottom-left-radius: 60px;
        border-bottom-right-radius: 60px;

    }
    .toolbar-logo {
        margin-left: 30%;
    }
}

@media screen and (max-width: 321px){
    .toolbar-logo {
        margin-left: 24%;
    }
}


.custom-sidebar{
    height: 100vh;
    background-color: white;
    /* box-shadow: 2px 0px 5px rgba(0,0,0,0.3); */
    position: fixed;
    top: 0;
    left: 0;
    width: 65%;
    max-width: 300px;
    background-color: white;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out !important;
}

@media screen and (max-width: 340px){
    .custom-sidebar{
        width: 78%;
    }
}

.custom-sidebar.open-sidebar{
    transform: translateX(0);
}

.custom-sidebar ul{
    list-style: none;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.custom-image-sidebar{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 3rem;
    cursor: pointer;
}

.custom-image-sidebar div{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    pointer-events: none;
}

.custom-image-sidebar div span:nth-child(1){
    font-weight: bold;
    margin-bottom: 0px;
    pointer-events: none;
}

.custom-image-sidebar div span:nth-child(2){
    margin-top: 0px;
    color: gray;
    pointer-events: none;
}

.custom-image-sidebar img{
    width: 60px;
    height: 60px;
    border-radius: 5px;
    pointer-events: none;
}

.tick-dp{
    width: 20px !important;
    height: 20px !important;
    position: absolute;
    top: 24px;
    right: 0px;
    pointer-events: none;
}

.custom-li-button{
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
    margin-bottom: 0.1rem;
}

.custom-li-button div{
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 16px;
    font-weight: 400;
    color:slategray;
    cursor: pointer;
}

.custom-li-button div span{
    margin-left: 30px;
    pointer-events: none;
}

.sidebar-icon{
    pointer-events: none;
    color: lightgray;
}

.custom-li-button div:hover{
    color: orangered !important;
}

.close-sidebar-button{
    display: flex !important;
    justify-content: flex-end;
    margin-top: 1rem;
    margin-right: 1.5rem;
    font-size: 18px;
    color: #F16529;
    cursor: pointer;
    pointer-events: all;
}

@media screen and (max-width: 576px){

.custom-image-sidebar div span:nth-child(1){
    font-size: 14px;
}

.custom-image-sidebar div span:nth-child(2){
    font-size: 14px;
}
}
.custom-backdrop{
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(0,0,0,0.3);
    z-index: 100;
}
.loading-container-main{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 150px;
}
.loading-container-inner{
    width: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: white;
}
.loading-cont-header{
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 10px;
    padding: 5px 0;
    color: #F37335;
    font-weight: 700;
    border-bottom: 2px solid #dadada;
}
.loading-screen-custom{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
}

.loader-text-custom{
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: gray;
}

@media screen and (max-width: 576px){
    .loading-container-main{
        margin-top: 4.5rem;
    }
    .loading-container-inner{
        width: 90%;
    }
}
.gameplay-top{
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}
.gameplay-inst{
    display: flex;
    justify-content: space-between; 
    width: 100%;
    margin-top: 30px;
}

.inst-image{
    display: flex;
    justify-content: center;
    width: 30%;
    border-top-right-radius: 12px;
    background-color: white;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12); 
    align-items: center;
    padding: 5px 20px;
    position: relative;
}

.inst-image img{
    width: 60px !important;
    height: 60px !important;
    object-fit: contain;
}

.inst-image div:nth-child(2){
    color: slategrey;
    font-weight: 500;
    font-size: 12px;
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
}

.ticker-inst{
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0px;
    left: 0px;
    align-items: center;
    font-size: 14px;
    padding: 2px 5px;
    border-radius: 4px;
    color: white;
    background-image: linear-gradient(to bottom, #87c6e7, #6db7e2, #53a8dd, #3699d8, #088ad2);
    display: flex;
    align-items: center;
    justify-content: center;

}


@media screen and (max-width: 767px){
    .gameplay-top{
        height: 150px;
        margin-bottom: 0rem;
    }
    .gameplay-inst{
        width: 100%;
        height: 100%; 
        align-items: center;
        margin-top: 0px;

    }
    .inst-image{
        width: 6em;
        height: 6em;
        border-radius: 80px;
        flex-direction: column;
        margin: 5px;
        align-items: center;
        justify-content: center;
        padding: 0%;
    }
    .inst-image img{
        width: 35px !important;
        height: 35px !important;
    }
    .ticker-inst{
        top: 5px;
        left: 10px;
        width: 15px;
        height: 15px;
    }
}
.slick-slide, .slick-slide *{ outline: none !important; }

.carousel-card-wrapper{
    background-color: white;
    height: 240px;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    position: relative;
}
.carousel-card-main{
    display: flex;
    padding: 10px 20px;
    height: 220px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.carousel-content-img{
    width: 25%;
    height: 160px;
    margin-right: 1.5rem;
}
.carousel-content-img img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.carousel-content-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-transform: uppercase;   
}
.carousel-title{
    color: #3c434e;
    font-size: 1.3em;
    font-weight: bolder;
    width: 100%;
}
.carousel-score-to-win{
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
  color:#fe7f3b;
  font-weight: bolder;
}
.carousel-score-to-win .part-a-score{
    margin-right: 4px;
}
.carousel-score-to-win .part-b-score{
    font-size: 16px;
    font-weight: bolder;
    color:#373e4a !important;
}
.carousel-counter{
    margin-top: 7px;
}
.carousel-button{
    display: flex;
    justify-content: space-between;
    align-items: center;    
    font-size: 0.87em;
    min-width: 150px;
    height: 40px;
    margin-top: 10px;
    font-weight: bolder;
    background: #FAA10D;  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #FAA10D, #FB6558); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 1px;
    box-shadow: 5px 10px 15px -8px #888888;
}
.play-icon{
    border-radius: 50%;
    color: white;
    padding-left: 5px;
    background: #FA6755;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #FA6755, #FA9B13); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    font-size: 23px;
    pointer-events: none;
    margin-right: 7px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}
.play-now{
    margin-left: 27px;
    pointer-events: none;
}
.slick-dots li button {
    font-size: 13px !important;
}
.slick-dots li.slick-active button:before {
    opacity: .75;
    color:#fd7e3b !important;
}
.slick-dots li button:before {
    font-size: 8px !important;
}
.slick-dots ul{
   
    margin-bottom: 1.2rem !important;
}
@media screen and (max-width: 840px) {
    .carousel-content-text{
       align-items: flex-start;
    }
    .slick-dots li{
        margin: 0 !important;
        width: 14px !important;
    }
    .slick-dots ul {
        margin-bottom: 0.9rem !important;
    }
    .carousel-content-img{
        width: 30%;
        height: 130px;
    }

}
@media screen and (max-width: 576px){
    .carousel-content-img{
        width: 40%;
        height: 110px;
        margin-right: 0.6rem;
    }  
    .carousel-card-wrapper{
        height: 198px;
    }
    .carousel-card-main{
        height: 180px;
    }
    .slick-dots li{
        margin: 0 !important;
        width: 18px !important;
    }
    .carousel-content-text{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;  
    }
    .carousel-title{
        width: 44vw;
        white-space: nowrap;
        overflow: hidden;
        font-size: 0.75em;
        text-overflow: ellipsis;
    }
    .carousel-score-to-win{
        margin-top: 5px;
        font-size: 11px;
    }
    .carousel-score-to-win .part-b-score{
        font-size: 12px;
    }
}

@media screen and (max-width:320px){
    .carousel-content-img {
        width: 25%;
        height: 70px;
        margin-right: 1.6rem;
       
    }
    .carousel-content-img img {
        width: 80px;
        height: 84px;
        border-radius: 5px;
        object-fit: fill;
         
     }
}
.games-main{
    background-color: #cecece;
    display: flex;
    justify-content: center;
    min-height: 100vh;
}
.games-container{
    width: 100%;
}
.game-screen{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    position: relative;
    padding: 0rem;
}

.game-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12); 
    padding: 10px 30px;
    margin: 1rem 2rem;
    width: 450px;
    transition: box-shadow .25s;
    position: relative;
}

.game-card:hover{
    box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
}

.level-indicator{
    position: absolute;
    top: -8px;
    background: rgb(48,135,194);
    background: linear-gradient(90deg, rgba(48,135,194,1) 0%, rgba(78,204,247,1) 100%);
    color: white;
    border: none;
    outline: none;
    border-radius: 60%;
    padding: 12px 10px;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    z-index: 10;
}

.gameplay-error-overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    background-color: slategray;
    z-index: -5;
    opacity: 0.75;
    visibility: hidden;
    pointer-events: none;
}

.gameplay-error-overlay span{
    font-size: 40px;
    color: white;
    letter-spacing: 5px;
    font-weight: bolder !important;
    opacity: 1 !important;
    display: block;
    z-index: 20 !important;
}

.show-overlay{
    visibility: visible !important;
    z-index: 5;
}
.main-container{
    background-color: #cecece;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.game-start-header{
    font-size: 1.22em;
    text-align: center;
    color: #000;
    margin-bottom: 1rem;
    font-weight: 900;
    background-color: white;
    transition: box-shadow 0.3s; 
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}
.selected{
    width: 80px;
    height: 80px;
}
.play_content{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    padding: 20px;
    width: 60%;
}
.how-to-play-content{
    display: flex;
    flex-direction: column;
    padding: 5%;
    font-weight: 400;
    font-size: 1.2em;
    margin-top: 4rem;
    z-index: 10;
    background-color: white;
    border-radius: 20px;
    color:#787d85;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12); 
    transition: box-shadow 0.3s;
    width: 100%;
}
.how-to-play-content p span{
    font-weight: bold;
}
.bottom-ply-button{
    display: flex;
    justify-content: center;
    align-items: center;
}
.how-to-play-buttons{
    margin-bottom: 0.5rem;
    padding: 0;
    display: flex;
    justify-content:space-between;
    align-items: center;
    font-size: 0.92em;
    height: 55px;
    width: 270px;
    font-weight: bolder;  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #FAA10D, #FB6558); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
    border-radius: 30px;
    cursor: pointer;
    border: none;
    text-transform: uppercase;
    letter-spacing: 1px; 
    box-shadow: 5px 10px 20px -8px #FB6558;
}
.play-now-button{
    margin-left: 4.3rem;
    font-size: 1.26rem;
    pointer-events: none;
}
.play-now-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    height: 45px;
    width: 45px;
    background: #FA6755;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #FA6755, #FA9B13);
    border-radius: 50%;
    font-size: 23px;
    pointer-events: none;
}
/* .how-to-play-buttons button:hover{
    transform: scale(1.2);
} */
.how-to-play-buttons button:focus{
    box-shadow: none;
    border: none;
    outline: none;
}
.game_level{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to left, #449fe7, #3dabec, #3db7f0, #43c3f2, #4fcef4);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    color: #ffff;
    margin-top: 5px;
}
.game_level_id{
    width: 25px;
    height: 25px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
}
.Instruction_list{
    margin-bottom: 10px;
    display: flex;
    flex-shrink: 0;
}
.game_instruction{
    margin-left: 10px;
    letter-spacing: 0.2px;
}
@media screen and (max-width: 576px){
.how-to-play-content{
        margin-top: 3rem;
        font-size: 0.89em;
        height: 65vh;
        overflow: auto;
       
}
.how-to-play-content-ios{
    margin-top: 3rem;
    font-size: 0.89em;
    overflow: scroll;
    height: 65vh;
}
.game-start-header-ios{
    margin: 28px 0px;
}
   /* .game-start-header{
        width: 90%;
    }*/
    .play_content{
        width: 100%;
    }
    .selected{
        width: 50px;
        height: 50px;
    }
}

.main-end-container{
background-color: #cecece;
min-height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
}
.end-game-container{
display: flex;
justify-content: center;
align-items: center;
padding: 20px;
width: 65%;
margin-top: 6rem;
}
.end-game{
font-weight: 400;
border-radius: 20px;
z-index: 10;
background-color: white;
color:#787d85;
box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12); 
transition: box-shadow 0.3s;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
.end-game-image img{
width: 140px;
height: 120px;
object-fit: cover;
margin: 1rem 0 2rem 0;
border-radius: 23px;
border: 1px solid  #d9d9d9;
}
.trophy-logo{
position: absolute;
top: 65px;
}
.trophy-logo img{
    width: 100px;
    height: 100px;
    z-index: 99;
}
.game-end-header{
padding: 35px 0px 10px 0px;
font-size: 2rem;
width: 100%;
text-align: center;
color: #fa7840;
font-weight:bold;
border-bottom: 1px solid #d9d9d9;
}

.bonus-points{
width: 100%;
padding: 15px;
font-size: 1.2rem;
text-align: center;
font-weight: 700;
text-transform: uppercase;
border-bottom: 1px solid #d9d9d9;
}
.bonus-points span:nth-child(1){
color: #000000;
margin-right: 7rem;
}
.bonus-points span:nth-child(2){
color: #fa882b;
}

.your-total-score{
width: 100%;
text-align: center;
padding: 15px;
font-weight: bold;
font-size: 1.2rem;
text-transform: uppercase;
border-bottom: 1px solid #d9d9d9;
}
.your-total-score span:nth-child(1){
color: #000000;
margin-right: 4rem;
}
.your-total-score span:nth-child(2){
color: #fa882b;
}
.congrats-end-game{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
font-weight: bolder;
margin-top: 1rem;
}
.congrats-end-game span:nth-child(1){
color:#3b3b3b;
font-size: 24px;
}
.congrats-end-game span:nth-child(2){
color: #3b3b3b;
opacity: 0.8;
font-size: 16px;
}
.congrats-end-game span:nth-child(3){
color: #3b3b3b;
opacity: 0.8;
font-size: 17px;
}
.end-game-buttons{
display: flex;
justify-content: center;
align-items: center;
width: 60%;
margin-bottom: 0.5rem;
margin-top: 1rem;
}
.end-game-buttons span{
display: flex;
justify-content: center;
align-items: center;
background: #FAA10D;  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to bottom, #FAA10D, #FB6558); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
color: white;
text-transform: uppercase;
font-weight: bold;
font-size: 13px;
letter-spacing: 1px;
border-radius: 30px;
min-width: 155px;
height: 47px;
margin: 0 5px;
cursor: pointer;
}
.end-game-buttons button:hover{
transform: scale(1.2);
}
.end-game-buttons button:focus{
box-shadow: none;
border: none;
outline: none;
}

@media screen and (max-width: 576px){

.end-game-container{
width: 100%;
margin-top: 5rem;
}
.end-game-container-ios{
    margin-top: 4rem;
}
.trophy-logo{
    top: 50px
}
.end-game-buttons{
    width: 95%;  
}
.end-game-buttons span{
    min-width: 130px;
}
.end-game-image img{
    width: 90px;
    height: 90px;
}
}

@media screen and (max-width:320px){
.bonus-points {
    font-size: 15px;
    padding: 10px;
}
.your-total-score{
    font-size: 15px;
    padding: 12px;
}
.end-game-container {
    margin-top: 4.5rem;
}
.end-game-container-ios{
    margin-top: 3.5rem;
}
.game-end-header {
    padding: 20px 0px 10px 0px;
    font-size: 20px;
}
.end-game-image img{
    width: 80px;
    height: 80px;
}
.trophy-logo img {
    width: 75px;
    height: 75px;
}
.congrats-end-game{
    margin-top: 5px;
}
.congrats-end-game span:nth-child(1){
    font-size: 19px;
    
    }
.congrats-end-game span:nth-child(2){
    font-size: 13px;
}
.congrats-end-game span:nth-child(3){
    font-size: 13px;
}
.end-game-buttons span{
    min-width: 129px;
    font-size: 11px;
}
}

.questions{
    display: block;
    font-size: 16px;
    text-align: center;
    word-wrap: break-word;
    font-weight: 600;
    margin-top: 0rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
}

.image-gallery{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.image-gallery .image-container{
    position: relative;
    top: 0;
    left: 0;
}

.tick-image{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.6;
    z-index: -1;
    transition: z-index all 0.1s;
}

.ticked{
    z-index: 1 !important;
}

.image-gallery img{
    pointer-events: none;
    width: 100px;
    height: 100px;
    border: 1px solid white;
    object-fit: fill;
}

.shake{
    animation: shake 0.6s;
}

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.game-loader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 280px;
    width: 100%;
}

.scoreboard-quiz{
    display: block;
    width: 100%;
    max-width: 270px;
    text-align: center;
    font-size: 18px;
    color: #eb5a3e;
    letter-spacing: 3px;
    font-weight: 900;
}

.scoreboard-quiz span{
    text-transform: uppercase;
    color: #eb5a3e;
    padding: 1px 3px;
    font-size: 16px;
    font-weight: 700;
    margin: 0px 5px;
}

@media screen and (max-width: 576px){
    .scoreboard-quiz{
        font-size: 12px;
    }
    .scoreboard-quiz span{
        font-size: 14px;
    }
    .image-gallery img{
        pointer-events: none;
        width: 80px;
        height: 80px;
        border: 1px solid white;
        object-fit: fill;
    }
}

@media screen and (max-width: 321px){
    .image-gallery img{
        pointer-events: none;
        width: 60px;
        height: 60px;
        border: 1px solid white;
        object-fit: fill;
    }
}

.custom-progress-bar{
    display: flex !important;
    justify-content: flex-start !important;
    margin-top: 1rem;
    width: 100%;
    max-width: 300px;
    padding: 0 0;
    border-radius: 20px;
    background-color: rgba(255,69,0,0.2);
}

.custom-progress-bar span{
    border-radius: 20px;
    border: 1px solid #eb5a3e;
}

.disable-click{
    pointer-events: none;
}

.image-changing-game{
    height: 400px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.image-changing-game div{
    margin: 0 20px;
    color: rgb(48,135,194);
    font-weight: bold;
    text-align: center;
    font-size: 1rem;
    letter-spacing: 1px;
}

@media screen and (max-width: 576px){
    .image-changing-game{
        height: 320px;
        width: 240px;
    }
    .custom-progress-bar{
        max-width: 240px;
    }
}

@media screen and (max-width: 321px){
    .image-changing-game{
        height: 240px;
        width: 180px;
    }
    .custom-progress-bar{
        max-width: 180px;
    }
}

.gameplay-error{
    position: absolute;
    top: -25px;
    background-color:  #95772e;
    color: white;
    border: none;
    outline: none;
    border-radius: 60%;
    padding: 10px 10px;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    z-index: 10;
    box-shadow: 0 0 8px 2px #95772e;
}

.gameplay-error div:nth-child(2){
    font-size: 12px
}

.gameplay-error div:nth-child(3){
    font-size: 12px
}

.hideattempt{
    visibility: hidden;
}

.timer-container-game{
    margin-top: 2.8rem;
}
.timer div{
    display: inline-flex;
    font-size: 1.5rem;
    border-radius: 2px;
    padding: 0px 2px;
    font-weight: bold;
    letter-spacing: 2px;
}

.warning-timer{
    color: #e7491a;
}

.success-timer{
    color: #479dd2;
}
.game-contest-card{
    margin-top: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.game-contest-card-inner{
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    width: 450px;
    height: 80px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    margin: 0 2rem;
    background-color: white; 
}

.game-contest-card-inner-img{
    width: 100px;
    height: 70px;
    margin-right: 1rem;
}

.game-contest-card-inner-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.game-contest-card-inner-text{
    display: flex;
    flex-direction: column;
    justify-self: center;
}

.game-contest-card-inner-text div:nth-child(1){
    font-size: 20px;
    font-weight: bold;
}


.game-contest-card-inner-text div:nth-child(2){
    font-size: 16px;
    font-weight: bold;
    color: orange;
    text-transform: uppercase;
}


.game-contest-card-inner-text div:nth-child(3){
    font-size: 16px;
    font-weight: bold;
    color: black
}

@media screen and (max-width: 924px){
    .game-contest-card-inner{
        width: 45%;
    }
}

@media screen and (max-width: 576px){
    .game-contest-card-inner{
        width: 90%;
    }

    .game-contest-card-inner-text div:nth-child(1){
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }


    .game-contest-card-inner-text div:nth-child(2){
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media screen and (max-width: 321px){
    .game-contest-card-inner{
        width: 80%;
    }
}
.profile-heading-custom{
    font-size: 20px;
    padding: 10px 12px;
    width: 100%;
    text-align: center;
    margin-top: 5rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #F37335;
    font-weight: 700;
}

.has-search3 .form-control {
    padding-left: 2.375rem;
    border-top: none;
    outline: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-radius: 0px;
}

.form-control:focus{
    outline: none;
    border-bottom: 2px solid #F37335;
    box-shadow: none !important;
}

.form-control-feedback3 {
    position: absolute;
    z-index: 2;
    display: block;
    width: 20px;
    height: 20px;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    text-align: center;
    pointer-events: none;
    color: #F37335;
}

.custom-profile-btn-class{
    background-color: #F37335 !important;
    opacity: 0.9 !important;
    border: none !important;
    outline: none !important;
    color: white !important;
    letter-spacing: 2px !important;
    text-transform: uppercase !important;
    border-radius: 30px;
    padding: 8px 10px !important;
    opacity: 0.8;
}

.custom-profile-btn-class:hover{
    background-color: #F37335 !important;
    opacity: 1 !important;
}

.custom-profile-btn-class:focus{
    box-shadow: none !important;
}

.custom-profile-btn-class:active{
    box-shadow: 0 1px 0 #F37335 !important;
    top: 1px !important;
}

.profile-image-custom{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.profile-image-custom img{
    width: 200px;
    height: 200px;
    border-radius: 50%;
}

.uploading-icon{
    color: #F37335;
    margin-top: 0.5rem;
    font-size: 24px;
}
.unsubText{
    color: #4a4a4a;
    text-align: center;
    font-size: 14px;
    font-style: italic;
    margin-top: 2%;
}
@media screen and (max-width: 576px){
    .profile-container{
        width: 90%;
        margin-top: 5rem;
    }
   
}
@media screen and (max-width: 340px){
    .profile-image-custom img{
        width: 150px;
        height: 150px;
    }
}

.custom-progress{
    width: 200px;
    font-size: 10px;
}

.user-details{
    display: flex;
    border: none;
    border: 1px solid white;
    background-color: whitesmoke;
    border-radius: 5px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 10px;
    margin-left: 2rem;
    font-size: 14px;
    transition: 0.5s;
    margin-bottom: 0.5rem;
}

.user-details div{
    display: flex;
    flex-direction: column;
}

.user-details div span:nth-child(1){
    font-weight: 500;
    font-style: normal;
}

.user-details div span:nth-child(2){
    font-weight: 600;
    font-style: normal;
}

.show-user-details{
    color: #F37335;
    display: flex;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
}

.show-user-details span:nth-child(1){
    font-size: 16px;
    margin-right: 10px;
    pointer-events: none;
}

.show-user-details span:nth-child(2){
    font-weight: bold;
    font-size: 16px;
    vertical-align: bottom;
    margin-right: 20px;
    pointer-events: none;
}

.show-user-details span:nth-child(3){
    font-size: 16px;
    font-weight: bold;
    pointer-events: none;
}

.noscore-heading-customs{
    display: flex;
    justify-content: center;
}
.no-score-card{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 700;
    margin: 2rem 0rem;
}
.myscore-main-container{
    background-color: white;
    min-height:100vh;
}
.myscore-custom-card{
    display: flex;
    justify-content: center;
    align-items: center;
}
.top-score-custom-card{
    margin-top: 6rem;
    background-color: white;
    z-index: 10;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: box-shadow 0.3s;
    margin-bottom: 1rem;
    width: 50%;
}
.myscore-header{
    font-size: 20px;
    color: #F37335;
    font-weight: bolder;
    text-align: center;
    padding: 10px 0px 7px 0px;
    border-bottom: 1px solid #d9d9d9;
    text-transform: uppercase;
    letter-spacing: 2px;
    
}
.custom-score-card{
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid #d9d9d9;
    padding: 15px 5px 7px 5px;
}
.product-image img {
    width: 150px;
    height: 100px;
    margin-left: 20px;
    object-fit: contain;
}
.details{
    width: 220px;
    height: auto;
    margin-left:70px;
}
.custom-product-name{
    font-weight: 700;
}
.your-score{
    margin-top: 5px;
    font-weight:bolder;
}
.your-score span:nth-child(1){
    color: #F37335;
}
.your-score span:nth-child(2){
    color: #3b3b3b;
    margin-left: 10px;
}
.myscore-button{  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #FAA10D, #FB6558); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
    border-radius: 30px;
    margin-top: 5px;
    width: 150px;
    height: 36px;
    display: flex;
    align-items: center;
    font-weight: 700;
    justify-content: space-around;
    cursor: pointer;
}
.top-10-custom-buttons{
    margin-left: 14px;
}
.top-10-custom-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FA6755;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #FA6755, #FA9B13);
    border-radius: 50%;
    margin-left: 9px;
    width: 27px;
    height: 27px;
  
}
@media screen and (max-width:1200px){
    .details{
       margin-left:25px;
    }
}

@media screen and (max-width:600px){
    .top-score-custom-card {
        width: 90%;
        margin-top: 4rem;
    }
    .details{
       margin-left:20px;
    }
    .product-image img {
        width: 110px;
        height: 80px;
    }
    
}
@media screen and (max-width:320px){
    .myscore-button {
        width: 130px;
        height: 32px;
        font-size: 0.9em;      
    }
    .details {
        font-size: 14px;
        margin-left:10px;
    }
}

.faq-heading-custom{
    font-size: 20px;
    padding: 10px 12px;
    width: 100%;
    text-align: center;
    margin-top: 5rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #F37335;
    font-weight: 700;
}
.faq-main{
    margin-bottom: 1rem;
}
.faq-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 3rem;
    padding: 0.5rem 0.5rem;
}

.faq-container .faq-question{
    font-weight: bold;
    text-align: justify;
    margin-bottom: 0.5rem;
}

.faq-answer{
    text-align: justify;
}

@media screen and (max-width: 576px){
    .faq-container{
        font-size: 14px;
    }
}
.contact-heading-custom{
    font-size: 20px;
    padding: 10px 12px;
    width: 100%;
    text-align: center;
    margin-top: 5rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #F37335;
    font-weight: 700;
}

.feedback-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
}

.feedback-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    text-align: center;
    font-weight: 500;
}

.feedback-star-ratings{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    width: 100%;
}

.rate-feedback{
    width: 35%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px 5px;
}

.rate-feedback span:nth-child(1){
    font-weight: 600;
}

.contact-page-buttons{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.5rem;
}

.extra-utils-contact{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    font-size: 1rem;
    color: slategray;
}

.extra-utils-contact button{
    margin-right: 10px;
    background: none;
    outline: none;
    border: none;
    text-transform: capitalize;
    color: slategray;
}

.feedback-text-comment{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedback-text-comment textarea{
    width: 40%;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 10px;
}

.feedback-text-comment textarea:focus{
    border: 1px solid black;
    outline: none;
}

.extra-utils-contact button:hover{
    color: black;
    font-weight: 700;
}

.feedback-button{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.feedback-button button{
    padding: 3px 20px;
    border-radius: 5px;
    border: none;
    outline: none;
    color: white;
    background-color: gray;
    text-transform: uppercase;
}

.feedback-button button:hover{
    background-color: black;
}

.feedback-button button:focus{
    outline: none;
    box-shadow: none;
    border: none;
}

.feedback-button button:active{
    top: 1px;
    box-shadow: 0 1px 0 black;
}

@media screen and (max-width: 768px){
    .extra-utils-contact{
        flex-direction: column;
    }
    .feedback-title{
        font-size: 0.9em;
    }
    .rate-feedback{
        width: 100%;
        font-size: 0.9em;
        margin-bottom: 5px;
        padding: 3px 3px;
    }
    .feedback-text-comment textarea{
        width: 90%;
    }
}

@media screen and (max-width: 320px){
    .feedback-title{
        font-size: 0.8em;
    }
    .rate-feedback{
        width: 100%;
        font-size: 0.75em;
        margin-bottom: 5px;
        padding: 0px 0px;
    }
    .feedback-text-comment textarea{
        width: 100%;
    }
    .extra-utils-contact{
        font-size: 0.8em;
    }
}
.o-page{
    position: fixed !important;
    min-width: 100% !important;
    min-height: 100% !important;
    z-index: -10;
    top: 0;
    left: 0;
    background: #fc4a1a;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #f7b733, #fc4a1a); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.o-contents{
    width: 300px;
    margin: auto;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;

}
.o-select-game-box{
    width: 150px;
    height: 150px;
}
.o-select-game-box div{
    width: 50%;
    height: 50%;
    float: left;
    display: flex;
    justify-content: center;
    
}
.o-select-game-box-content{
    margin: 5px;
    border: 1px solid white;
    width: 90% !important; 
    height: 90% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: white;
    color: #fc4a1a;
    font-size: 28px;
}
.o-active-play{
    animation-name: hover;
    animation-duration: 2s;
    background-color: #48b2eb;
    border: 1px solid #48b2eb;
    color: white;   
}
@keyframes hover{
    0%{
        background-color: white;
        border: 1px solid white;
        color: #fc4a1a;   
    }
    25%{
        background-color: white;
        border: 1px solid white;
        color: #fc4a1a;   
    }
    50%{
        background-color: white;
        border: 1px solid white;
        color: #fc4a1a;   
    }
    51%{
        background-color: #48b2eb;
        border: 1px solid #48b2eb;
        color: white;   
    }
    100%{
        background-color: #48b2eb;
        border: 1px solid #48b2eb;
        color: white;   
    }
}
.o-select-game-box-text{
    margin-top: 3rem;
    font-size: 24px;
    color: white;
    font-weight: 500;
    opacity: 0.8;
    display: block;
    text-transform: capitalize;
}
.o-select-game-box-sub{
    margin-top: 1rem;
    display: block;
    text-align: center;
    color: white;
    opacity: 0.6;
    font-weight: 500;
}
.o-select-hand{
    position: absolute;
    top: 47%;
    right: 70px;
    animation-name: animatehand;
    animation-duration: 2s;
}
@keyframes animatehand{
    0% {
        opacity: 0;
        right: -100px;
        z-index: 100;
    }
    25% {
        opacity: 0;
        right: -100px;
        z-index: 100;
    }
    50% {
        opacity: 1;
        right: 70px;
        z-index: 100;
    }
    75% {
        opacity: 1;
        right: 70px;
        z-index: 100;
    }
}
.o-select-hand img{
    width: 40px;
    height: auto;
}
.o-next-btn{
    position: fixed;
    bottom: 25px;
    right: 30px;
    z-index: 100;
}
.o-next-btn button{
    background: none;
    border: none;
    outline: none;
    color: white;
    letter-spacing: 1px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}
.o-next-btn button:active{
    opacity: 0.5;
}
.o-get-started-btn{
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
}
.o-get-started-btn button{
    background: white;
    color: #fc4a1a;
    outline: none;
    border: none;
    padding: 8px 15px;
    box-shadow: none;
    font-weight: 500;
    border-radius: 30px;
    text-transform: capitalize;
}
.o-get-started-btn button:active{
    top: 1px;
    box-shadow: 0 1px 0 white;
}
.o-dots{
    position: absolute;
    bottom: 23px;
    left: 30px;
}
.o-dot{
    height: 10px;
    width: 10px;
    margin: 0px 2px;
    background-color: #fc4a1a;
    opacity: 0.5;
    display: inline-block;
    border-radius: 50%;
}
.show-dot{
    opacity: 1 !important;
}
.o-play-game-box{
    position: relative;
    width: 100%;
    height: 150px;
}
.o-play-game-box img:nth-child(1){
    position: absolute;
    left: 14%;
    top: 26%;
    z-index: -10;
    animation-name: play-1;
    animation-duration: 2s;
}
.o-play-game-box img:nth-child(2){
    position: absolute;
    z-index: 100;
    left: 34%;
    animation-name: play-2;
    animation-duration: 2s;
}
.o-play-game-box img:nth-child(3){
    position: absolute;
    z-index: -10;
    top: 21%;
    left: 60%;
    animation-name: play-3;
    animation-duration: 2s;
}
@keyframes play-1 {
    0%   { 
        left: 35%;
        opacity: 0;
    }
    25%  { 
        left:35%;
        opacity: 0;
    }
    50%  { 
        left:35%;
        opacity: 0;
    }
    75%  { 
        left: 14%;
        opacity: 1;
    }
    100% { 
        left: 14%;
        opacity: 1;
    }  
}
@keyframes play-2 {
    0%   { 
        opacity: 0;
    }
    25%  { 
        opacity: 0;
    }
    40%  { 
        opacity: 1;
    }
    75%  { 
        opacity: 1;
    }
    100% { 
        opacity: 1;
    }  
}
@keyframes play-3 {
    0%   { 
        left: 35%;
        opacity: 0;
    }
    25%  { 
        left:35%;
        opacity: 0;
    }
    50%  { 
        left:35%;
        opacity: 0;
    }
    75%  { 
        left: 60%;
        opacity: 1;
    }
    100% { 
        left: 60%;
        opacity: 1;
    }  
}
.o-claim-game-box{
    position: relative;
    width: 100%;
    height: 220px;
}
.o-claim-game-box img:nth-child(2){
    position: absolute;
    top: 2%;
    left: 30%;
    z-index: 100;
    animation-name: claim-2;
    animation-duration: 2s;
}
.o-claim-game-box img:nth-child(1){
    position: absolute;
    top: 50%;
    left: 15%;
    z-index: -10;
    animation-name: claim-1;
    animation-duration: 2s;
}
.o-claim-game-box img:nth-child(3){
    position: absolute;
    top: 55%;
    left: 56%;
    z-index: -10;
    animation-name: claim-3;
    animation-duration: 2s;
}
@keyframes claim-1 {
    0%{
        left: 32%;
        opacity: 0;
    }
    25%  { 
        left:32%;
        opacity: 0;
    }
    50%  { 
        left:32%;
        opacity: 0;
    }
    75%  { 
        left: 15%;
        opacity: 1;
    }
    100% { 
        left: 15%;
        opacity: 1;
    }  
}
@keyframes claim-2 {
    0%{
        opacity: 0;
    }
    25%  { 
        opacity: 0;
    }
    40%  { 
        opacity: 1;
    }
    75%  { 
        opacity: 1;
    }
    100% { 
        opacity: 1;
    }
}  
@keyframes claim-3 {
    0%{
        left: 36%;
        opacity: 0;
    }
    25%  { 
        left:36%;
        opacity: 0;
    }
    50%  { 
        left:36%;
        opacity: 0;
    }
    75%  { 
        left: 56%;
        opacity: 1;
    }
    100% { 
        left: 56%;
        opacity: 1;
    }  
}
.main-leaderboard-container {
    background-color: #cecece;
    min-height: 100vh;
    display: flex;
    justify-content: center;
}

.leaderboard-container {
    margin-top: 85px;
    width: 55%;
    z-index: 10;
}

.top-10-text {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    color: #777;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.top-10-header {
    background-color:white;
    box-shadow: -1px 6px 9px -1px rgba(0, 0, 0, 0.38);
    transition: box-shadow 0.3s;
    border-radius: 20px;
}
.img-content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
}

.top-10-header-image {
    padding: 10px;
    border-radius: 50%;
    margin-right: 1rem;
}

.top-10-header-image img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    object-fit: cover;
}

.top-10-header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.header-top-title {
    font-size: 0.9rem;
    color: #333;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
}

.best-score-title {
    font-size: 0.9rem;
    font-weight: bolder;
}

.best-score-title span:nth-child(1) {
    color: #fa882b;
}

.best-score-title span:nth-child(2) {
    color: black;
    padding-left: 5px;
}

.best-rank-title {
    font-size: 0.9rem;
    font-weight: bolder;
    text-transform: capitalize;
    letter-spacing: 1px;
}

.best-rank-title span:nth-child(1) {
    color: green;
}

.best-rank-title span:nth-child(2) {
    color: black;
    padding-left: 5px;
}

.top-10-list-main {
    margin-top: 1.5rem;
    border-radius: 20px;
    background-color:white;
    box-shadow: -1px 6px 9px -1px rgba(0, 0, 0, 0.38);
    transition: box-shadow 0.3s;
    margin-bottom: 1.5rem;
}

.top-10-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    border-bottom: 2px solid #dadada;
    padding: 17px 25px 10px 25px;
}

.top-10-list-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 120px;
}

.top-10-id {
    color:#555;
    font-weight: 900;
    font-size: 1.2rem;
    border-radius: 10px;
    width: 30px;
    text-align: center;
}

.top-10-car-img img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.top-10-card-name {
    color: #2b2b2b;
    font-weight: 900;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 1rem;
    margin-left: 5px;
}

.top-10-card-name span:nth-child(2){
    font-size: 0.9rem;
    color: #777;
    font-weight: 500;
}

.top-10-heading-text{
    text-align: center;
    font-size: 1.3rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fa882b;
    font-weight: bold;
    padding: 15px 0px 10px 0px;
    border-bottom: 2px solid #d9d9d9;
}

.top-10-time {
    color: #2b2b2b;
    font-weight: 900;
    font-size: 1.2rem;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.leader-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    cursor: pointer;
}

.leader-button span {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #faa10d;
    background: linear-gradient(to bottom,
            #faa10d,
            #fb6558);
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 1px;
    border-radius: 30px;
    min-width: 280px;
    height: 55px;
    pointer-events: none;
}

@media screen and (max-width: 1200px) {
    .top-10-list-card {
        width: 30%;
    }
}

@media screen and (max-width: 800px) {
    .top-10-list-card {
        width: 41%;
    }
}

@media screen and (max-width: 600px) {
    .top-10-list-card {
        width: 53%;
    }
    .top-10-header{
        margin-top: 1rem;
    }
}

@media screen and (max-width: 576px) {
    .leaderboard-container {
        width: 90%;
       margin-top: 60px;
    }

    .top-10-list-card {
        width: 45%;
    }
    .top-10-car-img img {
        width: 35px;
        height: 35px;
    }
    .top-10-time{
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 320px) {
    .top-10-list-card {
        width: 53%;
    }
}
