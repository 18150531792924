.contact-heading-custom{
    font-size: 20px;
    padding: 10px 12px;
    width: 100%;
    text-align: center;
    margin-top: 5rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #F37335;
    font-weight: 700;
}

.feedback-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
}

.feedback-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    text-align: center;
    font-weight: 500;
}

.feedback-star-ratings{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    width: 100%;
}

.rate-feedback{
    width: 35%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px 5px;
}

.rate-feedback span:nth-child(1){
    font-weight: 600;
}

.contact-page-buttons{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.5rem;
}

.extra-utils-contact{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    font-size: 1rem;
    color: slategray;
}

.extra-utils-contact button{
    margin-right: 10px;
    background: none;
    outline: none;
    border: none;
    text-transform: capitalize;
    color: slategray;
}

.feedback-text-comment{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedback-text-comment textarea{
    width: 40%;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 10px;
}

.feedback-text-comment textarea:focus{
    border: 1px solid black;
    outline: none;
}

.extra-utils-contact button:hover{
    color: black;
    font-weight: 700;
}

.feedback-button{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.feedback-button button{
    padding: 3px 20px;
    border-radius: 5px;
    border: none;
    outline: none;
    color: white;
    background-color: gray;
    text-transform: uppercase;
}

.feedback-button button:hover{
    background-color: black;
}

.feedback-button button:focus{
    outline: none;
    box-shadow: none;
    border: none;
}

.feedback-button button:active{
    top: 1px;
    box-shadow: 0 1px 0 black;
}

@media screen and (max-width: 768px){
    .extra-utils-contact{
        flex-direction: column;
    }
    .feedback-title{
        font-size: 0.9em;
    }
    .rate-feedback{
        width: 100%;
        font-size: 0.9em;
        margin-bottom: 5px;
        padding: 3px 3px;
    }
    .feedback-text-comment textarea{
        width: 90%;
    }
}

@media screen and (max-width: 320px){
    .feedback-title{
        font-size: 0.8em;
    }
    .rate-feedback{
        width: 100%;
        font-size: 0.75em;
        margin-bottom: 5px;
        padding: 0px 0px;
    }
    .feedback-text-comment textarea{
        width: 100%;
    }
    .extra-utils-contact{
        font-size: 0.8em;
    }
}