.login_logo{
    text-align: center;
    height: 63px;
    width: 150px;
}

.login_page{
    position: fixed !important;
    min-width: 100% !important;
    min-height: 100% !important;
    z-index: -10;
    background: #fc4a1a;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #f7b733, #fc4a1a);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #f7b733, #fc4a1a); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.login_page_main{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20vh;
}

.text-content{
    color: white;
    text-align: center;
}

.text-content div:nth-child(1){
    font-size: x-large;
    font-weight: 400;
}

.text-content div:nth-child(2){
    opacity: 0.6;
}