.o-page{
    position: fixed !important;
    min-width: 100% !important;
    min-height: 100% !important;
    z-index: -10;
    top: 0;
    left: 0;
    background: #fc4a1a;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #f7b733, #fc4a1a);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #f7b733, #fc4a1a); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.o-contents{
    width: 300px;
    margin: auto;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;

}
.o-select-game-box{
    width: 150px;
    height: 150px;
}
.o-select-game-box div{
    width: 50%;
    height: 50%;
    float: left;
    display: flex;
    justify-content: center;
    
}
.o-select-game-box-content{
    margin: 5px;
    border: 1px solid white;
    width: 90% !important; 
    height: 90% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: white;
    color: #fc4a1a;
    font-size: 28px;
}
.o-active-play{
    animation-name: hover;
    animation-duration: 2s;
    background-color: #48b2eb;
    border: 1px solid #48b2eb;
    color: white;   
}
@keyframes hover{
    0%{
        background-color: white;
        border: 1px solid white;
        color: #fc4a1a;   
    }
    25%{
        background-color: white;
        border: 1px solid white;
        color: #fc4a1a;   
    }
    50%{
        background-color: white;
        border: 1px solid white;
        color: #fc4a1a;   
    }
    51%{
        background-color: #48b2eb;
        border: 1px solid #48b2eb;
        color: white;   
    }
    100%{
        background-color: #48b2eb;
        border: 1px solid #48b2eb;
        color: white;   
    }
}
.o-select-game-box-text{
    margin-top: 3rem;
    font-size: 24px;
    color: white;
    font-weight: 500;
    opacity: 0.8;
    display: block;
    text-transform: capitalize;
}
.o-select-game-box-sub{
    margin-top: 1rem;
    display: block;
    text-align: center;
    color: white;
    opacity: 0.6;
    font-weight: 500;
}
.o-select-hand{
    position: absolute;
    top: 47%;
    right: 70px;
    animation-name: animatehand;
    animation-duration: 2s;
}
@keyframes animatehand{
    0% {
        opacity: 0;
        right: -100px;
        z-index: 100;
    }
    25% {
        opacity: 0;
        right: -100px;
        z-index: 100;
    }
    50% {
        opacity: 1;
        right: 70px;
        z-index: 100;
    }
    75% {
        opacity: 1;
        right: 70px;
        z-index: 100;
    }
}
.o-select-hand img{
    width: 40px;
    height: auto;
}
.o-next-btn{
    position: fixed;
    bottom: 25px;
    right: 30px;
    z-index: 100;
}
.o-next-btn button{
    background: none;
    border: none;
    outline: none;
    color: white;
    letter-spacing: 1px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}
.o-next-btn button:active{
    opacity: 0.5;
}
.o-get-started-btn{
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
}
.o-get-started-btn button{
    background: white;
    color: #fc4a1a;
    outline: none;
    border: none;
    padding: 8px 15px;
    box-shadow: none;
    font-weight: 500;
    border-radius: 30px;
    text-transform: capitalize;
}
.o-get-started-btn button:active{
    top: 1px;
    box-shadow: 0 1px 0 white;
}
.o-dots{
    position: absolute;
    bottom: 23px;
    left: 30px;
}
.o-dot{
    height: 10px;
    width: 10px;
    margin: 0px 2px;
    background-color: #fc4a1a;
    opacity: 0.5;
    display: inline-block;
    border-radius: 50%;
}
.show-dot{
    opacity: 1 !important;
}
.o-play-game-box{
    position: relative;
    width: 100%;
    height: 150px;
}
.o-play-game-box img:nth-child(1){
    position: absolute;
    left: 14%;
    top: 26%;
    z-index: -10;
    animation-name: play-1;
    animation-duration: 2s;
}
.o-play-game-box img:nth-child(2){
    position: absolute;
    z-index: 100;
    left: 34%;
    animation-name: play-2;
    animation-duration: 2s;
}
.o-play-game-box img:nth-child(3){
    position: absolute;
    z-index: -10;
    top: 21%;
    left: 60%;
    animation-name: play-3;
    animation-duration: 2s;
}
@keyframes play-1 {
    0%   { 
        left: 35%;
        opacity: 0;
    }
    25%  { 
        left:35%;
        opacity: 0;
    }
    50%  { 
        left:35%;
        opacity: 0;
    }
    75%  { 
        left: 14%;
        opacity: 1;
    }
    100% { 
        left: 14%;
        opacity: 1;
    }  
}
@keyframes play-2 {
    0%   { 
        opacity: 0;
    }
    25%  { 
        opacity: 0;
    }
    40%  { 
        opacity: 1;
    }
    75%  { 
        opacity: 1;
    }
    100% { 
        opacity: 1;
    }  
}
@keyframes play-3 {
    0%   { 
        left: 35%;
        opacity: 0;
    }
    25%  { 
        left:35%;
        opacity: 0;
    }
    50%  { 
        left:35%;
        opacity: 0;
    }
    75%  { 
        left: 60%;
        opacity: 1;
    }
    100% { 
        left: 60%;
        opacity: 1;
    }  
}
.o-claim-game-box{
    position: relative;
    width: 100%;
    height: 220px;
}
.o-claim-game-box img:nth-child(2){
    position: absolute;
    top: 2%;
    left: 30%;
    z-index: 100;
    animation-name: claim-2;
    animation-duration: 2s;
}
.o-claim-game-box img:nth-child(1){
    position: absolute;
    top: 50%;
    left: 15%;
    z-index: -10;
    animation-name: claim-1;
    animation-duration: 2s;
}
.o-claim-game-box img:nth-child(3){
    position: absolute;
    top: 55%;
    left: 56%;
    z-index: -10;
    animation-name: claim-3;
    animation-duration: 2s;
}
@keyframes claim-1 {
    0%{
        left: 32%;
        opacity: 0;
    }
    25%  { 
        left:32%;
        opacity: 0;
    }
    50%  { 
        left:32%;
        opacity: 0;
    }
    75%  { 
        left: 15%;
        opacity: 1;
    }
    100% { 
        left: 15%;
        opacity: 1;
    }  
}
@keyframes claim-2 {
    0%{
        opacity: 0;
    }
    25%  { 
        opacity: 0;
    }
    40%  { 
        opacity: 1;
    }
    75%  { 
        opacity: 1;
    }
    100% { 
        opacity: 1;
    }
}  
@keyframes claim-3 {
    0%{
        left: 36%;
        opacity: 0;
    }
    25%  { 
        left:36%;
        opacity: 0;
    }
    50%  { 
        left:36%;
        opacity: 0;
    }
    75%  { 
        left: 56%;
        opacity: 1;
    }
    100% { 
        left: 56%;
        opacity: 1;
    }  
}