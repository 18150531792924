.main-end-container{
background-color: #cecece;
min-height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
}
.end-game-container{
display: flex;
justify-content: center;
align-items: center;
padding: 20px;
width: 65%;
margin-top: 6rem;
}
.end-game{
font-weight: 400;
border-radius: 20px;
z-index: 10;
background-color: white;
color:#787d85;
box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12); 
transition: box-shadow 0.3s;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
.end-game-image img{
width: 140px;
height: 120px;
object-fit: cover;
margin: 1rem 0 2rem 0;
border-radius: 23px;
border: 1px solid  #d9d9d9;
}
.trophy-logo{
position: absolute;
top: 65px;
}
.trophy-logo img{
    width: 100px;
    height: 100px;
    z-index: 99;
}
.game-end-header{
padding: 35px 0px 10px 0px;
font-size: 2rem;
width: 100%;
text-align: center;
color: #fa7840;
font-weight:bold;
border-bottom: 1px solid #d9d9d9;
}

.bonus-points{
width: 100%;
padding: 15px;
font-size: 1.2rem;
text-align: center;
font-weight: 700;
text-transform: uppercase;
border-bottom: 1px solid #d9d9d9;
}
.bonus-points span:nth-child(1){
color: #000000;
margin-right: 7rem;
}
.bonus-points span:nth-child(2){
color: #fa882b;
}

.your-total-score{
width: 100%;
text-align: center;
padding: 15px;
font-weight: bold;
font-size: 1.2rem;
text-transform: uppercase;
border-bottom: 1px solid #d9d9d9;
}
.your-total-score span:nth-child(1){
color: #000000;
margin-right: 4rem;
}
.your-total-score span:nth-child(2){
color: #fa882b;
}
.congrats-end-game{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
font-weight: bolder;
margin-top: 1rem;
}
.congrats-end-game span:nth-child(1){
color:#3b3b3b;
font-size: 24px;
}
.congrats-end-game span:nth-child(2){
color: #3b3b3b;
opacity: 0.8;
font-size: 16px;
}
.congrats-end-game span:nth-child(3){
color: #3b3b3b;
opacity: 0.8;
font-size: 17px;
}
.end-game-buttons{
display: flex;
justify-content: center;
align-items: center;
width: 60%;
margin-bottom: 0.5rem;
margin-top: 1rem;
}
.end-game-buttons span{
display: flex;
justify-content: center;
align-items: center;
background: #FAA10D;  
background: -webkit-linear-gradient(to bottom, #FAA10D, #FB6558);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to bottom, #FAA10D, #FB6558); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
color: white;
text-transform: uppercase;
font-weight: bold;
font-size: 13px;
letter-spacing: 1px;
border-radius: 30px;
min-width: 155px;
height: 47px;
margin: 0 5px;
cursor: pointer;
}
.end-game-buttons button:hover{
transform: scale(1.2);
}
.end-game-buttons button:focus{
box-shadow: none;
border: none;
outline: none;
}

@media screen and (max-width: 576px){

.end-game-container{
width: 100%;
margin-top: 5rem;
}
.end-game-container-ios{
    margin-top: 4rem;
}
.trophy-logo{
    top: 50px
}
.end-game-buttons{
    width: 95%;  
}
.end-game-buttons span{
    min-width: 130px;
}
.end-game-image img{
    width: 90px;
    height: 90px;
}
}

@media screen and (max-width:320px){
.bonus-points {
    font-size: 15px;
    padding: 10px;
}
.your-total-score{
    font-size: 15px;
    padding: 12px;
}
.end-game-container {
    margin-top: 4.5rem;
}
.end-game-container-ios{
    margin-top: 3.5rem;
}
.game-end-header {
    padding: 20px 0px 10px 0px;
    font-size: 20px;
}
.end-game-image img{
    width: 80px;
    height: 80px;
}
.trophy-logo img {
    width: 75px;
    height: 75px;
}
.congrats-end-game{
    margin-top: 5px;
}
.congrats-end-game span:nth-child(1){
    font-size: 19px;
    
    }
.congrats-end-game span:nth-child(2){
    font-size: 13px;
}
.congrats-end-game span:nth-child(3){
    font-size: 13px;
}
.end-game-buttons span{
    min-width: 129px;
    font-size: 11px;
}
}
