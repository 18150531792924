.App{
    font-family: 'Nunito','Open Sans', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
    -webkit-text-stroke: 0px !important;
    text-emphasis: none;
    font-feature-settings: "kern" 1;
    text-rendering: optimizeLegibility;

}