.custom-input-container{
    position: relative;
}

.custom-input-container input {
    width: 100%;
    padding: 8px 2rem;
    border-radius: 30px;
    border: 2px solid white;
    outline: none;
    color: gray;
}

.custom-input-container span:nth-child(1) {
    position: absolute;
    z-index: 2;
    display: block;
    width: 20px;
    height: 20px;
    margin-top: 0.61rem;
    margin-left: 0.5rem;
    text-align: center;
    pointer-events: none;
    color: lightgray;
}

.custom-input-container span:nth-child(2) {
    position: absolute;
    z-index: 2;
    display: block;
    width: 30px;
    height: 25px;
    margin-top: 0.61rem;
    margin-left: 2rem;
    text-align: center;
    pointer-events: none;
    color: gray;
    opacity: 0.5;
}

.validate-input-icon{
    position: absolute;
    right: 30px;
    top: 11px;
    font-size: 20px;
}