.game-contest-card{
    margin-top: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.game-contest-card-inner{
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    width: 450px;
    height: 80px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    margin: 0 2rem;
    background-color: white; 
}

.game-contest-card-inner-img{
    width: 100px;
    height: 70px;
    margin-right: 1rem;
}

.game-contest-card-inner-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.game-contest-card-inner-text{
    display: flex;
    flex-direction: column;
    justify-self: center;
}

.game-contest-card-inner-text div:nth-child(1){
    font-size: 20px;
    font-weight: bold;
}


.game-contest-card-inner-text div:nth-child(2){
    font-size: 16px;
    font-weight: bold;
    color: orange;
    text-transform: uppercase;
}


.game-contest-card-inner-text div:nth-child(3){
    font-size: 16px;
    font-weight: bold;
    color: black
}

@media screen and (max-width: 924px){
    .game-contest-card-inner{
        width: 45%;
    }
}

@media screen and (max-width: 576px){
    .game-contest-card-inner{
        width: 90%;
    }

    .game-contest-card-inner-text div:nth-child(1){
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }


    .game-contest-card-inner-text div:nth-child(2){
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media screen and (max-width: 321px){
    .game-contest-card-inner{
        width: 80%;
    }
}