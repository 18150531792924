.img-card {
  width: 100%;
  height:140px;
  display:block;
  overflow: hidden;
  pointer-events: none;
}

.img-card img{
  width: 100%;
  height: 140px;
  object-fit:contain; 
  pointer-events: none;
}
.disable-click-card{
  pointer-events: none;
}

.custom-card{
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12); 
  padding: 5px;
  height: 20rem;
  border-radius: 5px;
  transition: box-shadow 0.3s;
  cursor: pointer;
  position: relative;
}

.custom-card:hover{
  box-shadow: 0 2px 5px 3px rgba(0,0,0,0.16),0 2px 10px 3px rgba(0,0,0,0.12); 
}

.custom-card-title{
  color: #373e4a;
  padding: 1px 5px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0px;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-card-win-score{
  padding: 0px 5px;
  display: flex;
  justify-content: center;
  margin-top: 0px;
  font-size: 14px;
  color:orange;
  font-weight: bold;
  pointer-events: none;
}

.custom-card-score{
  padding: 0px 5px;
  display: flex;
  justify-content: center;
  margin-top: 0px;
  font-size: 16px;
  color: black;
  font-weight: bold;
  pointer-events: none;
}

.custom-card-counter{
  display: flex;
  justify-content: center;
  margin-top: 10px;
  pointer-events: none;
}

.custom-card-button{
  position: relative;
  margin: 0 5%;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.custom-card-button button{
  padding: 4px 30px;
  font-weight: bold;
  background: #FAA10D;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #FAA10D, #FB6558);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #FAA10D, #FB6558); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  width: 100%;
  outline: none;
  display: flex;
  justify-content: flex-start;
  border-radius: 30px;
  border: none;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 0px;
  pointer-events: none;
}

.custom-card-button button:focus{
  box-shadow: none;
  outline: none;
  border: none;
}

.custom-card-button button:active{
  top: 1px;
  box-shadow: 0 1px 0 #FAA10D;
}

.custom-card-button span{
  position: absolute;
  float: right;
  text-align: right;
  right: 3%;
  top: 3px;
  border-radius: 50%;
  color: white;
  padding: 1px 10px;
  background: #FA6755;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FA6755, #FA9B13);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right,#FA6755, #FA9B13); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  font-size: 16px;
  pointer-events: none;
}

.custom-card-play-button{
  display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    pointer-events: none;
}
.counter-wrapper{
  margin-top: 10px;
  pointer-events: none;
}
.bubble {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top:43px;
  left: 2.2em;
    position: absolute;
    background: linear-gradient(to left, #449fe7, #3dabec, #3db7f0, #43c3f2, #4fcef4);
    color: #FFFFFF;
    font-family: Arial;
    text-align: center;
    width: 57px;
    height: 57px;
    border-radius: 50%;

}
.bubble:after {
    content: '';
    position: absolute;
    display: block;
    z-index: 1;
    border-style: solid;
    border-color: #449fe7 transparent;
    border-width: 12px 4px 0;
    bottom: 6px;
    left: 91%;
    transform: rotate(291deg);
}

.bubble-product1{
  font-size: 0.7rem;
  font-weight: bolder; 
}
.bubble-product2{
  font-size: 0.55rem;
  font-weight: bold;
}
@media screen and (max-width:1200px){
  .bubble{
    left: 52px;
  }
  
}
@media screen and (max-width:800px){
  .bubble{
    left: 72px;
  }
}
@media screen and (max-width:600px){
  .bubble{
    left: 30px;
  }
}
@media screen and (max-width: 576px){
  .custom-card-button button{
    justify-content: center;
    width: 70%;
    font-size: 12px;
  }
  .custom-card{
    width: 47%;
    height: 19rem;
  }
  .custom-card-button span{
    padding: 3px 8px;
    right: 16%;
    font-size: 10px;
  }
 .custom-card-title {
   padding: 0;
   font-size: 13px;
 }
  .bubble{
    left: 10px;
}
}
@media screen and (max-width:385px){
  .bubble{
    left: 7px;
  }
}
@media screen and (max-width:320px){
  .custom-card-title {
    font-size: 12px;
  }
  .bubble{
    top: 50px;
    width: 43px;
    height: 43px;
  }
  .bubble-product1 {
    font-size: 9px;
    font-weight: bolder;
}
.bubble-product2 {
  font-size: 7px;
  font-weight: bold;
}
}