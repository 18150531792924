.custom-sidebar{
    height: 100vh;
    background-color: white;
    /* box-shadow: 2px 0px 5px rgba(0,0,0,0.3); */
    position: fixed;
    top: 0;
    left: 0;
    width: 65%;
    max-width: 300px;
    background-color: white;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out !important;
}

@media screen and (max-width: 340px){
    .custom-sidebar{
        width: 78%;
    }
}

.custom-sidebar.open-sidebar{
    transform: translateX(0);
}

.custom-sidebar ul{
    list-style: none;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.custom-image-sidebar{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 3rem;
    cursor: pointer;
}

.custom-image-sidebar div{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    pointer-events: none;
}

.custom-image-sidebar div span:nth-child(1){
    font-weight: bold;
    margin-bottom: 0px;
    pointer-events: none;
}

.custom-image-sidebar div span:nth-child(2){
    margin-top: 0px;
    color: gray;
    pointer-events: none;
}

.custom-image-sidebar img{
    width: 60px;
    height: 60px;
    border-radius: 5px;
    pointer-events: none;
}

.tick-dp{
    width: 20px !important;
    height: 20px !important;
    position: absolute;
    top: 24px;
    right: 0px;
    pointer-events: none;
}

.custom-li-button{
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
    margin-bottom: 0.1rem;
}

.custom-li-button div{
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 16px;
    font-weight: 400;
    color:slategray;
    cursor: pointer;
}

.custom-li-button div span{
    margin-left: 30px;
    pointer-events: none;
}

.sidebar-icon{
    pointer-events: none;
    color: lightgray;
}

.custom-li-button div:hover{
    color: orangered !important;
}

.close-sidebar-button{
    display: flex !important;
    justify-content: flex-end;
    margin-top: 1rem;
    margin-right: 1.5rem;
    font-size: 18px;
    color: #F16529;
    cursor: pointer;
    pointer-events: all;
}

@media screen and (max-width: 576px){

.custom-image-sidebar div span:nth-child(1){
    font-size: 14px;
}

.custom-image-sidebar div span:nth-child(2){
    font-size: 14px;
}
}