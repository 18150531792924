.custom-alert{
    position: fixed;
    background-color: darkgreen;
    color: white;
    width: 100%;
    z-index: 0;
    text-align: center;
    text-transform: uppercase;
    transform: translateY(-100%);
    transition: transform 0.5s ease-in-out;
}

.show-alert{
    transform: translateY(0);
}