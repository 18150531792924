.account_page{
    position: fixed !important;
    min-width: 100% !important;
    min-height: 100% !important;
    z-index: -10;
    background: #fc4a1a;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #f7b733, #fc4a1a);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #f7b733, #fc4a1a); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.account_page_main{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3vh;
}

.or_divider{
    text-align: center;
    margin: 3px 0;
    opacity: 0.6;
    color: white;
}

.has-search2 .form-control {
    padding-left: 2.375rem;
}

.has-search2 .form-control-feedback2 {
    position: absolute;
    z-index: 2;
    display: block;
    width: 20px;
    height: 20px;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    text-align: center;
    pointer-events: none;
    color: lightgray;
}

.custom_fb_button{
    background-color:#227bef;
    text-transform: uppercase;
    border: none;
    outline: none;
    color: white;
    padding: 7px 10px;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    border-radius: 30px;
    letter-spacing: 2px;
}

.custom_fb_button:hover{
    background-color: #227bef !important;
}

.custom_fb_button:focus{
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.custom_fb_button:active{
    box-shadow: 0 1px 0 blue !important;
    top: 1px !important;
}

.custom_fb_button_container{
    position: relative;
}

.fa-facebook{
    position: absolute;
    float: left;
    font-size: 26px;
    color: white;
    right: 1.2%;
    top: 5px;
}

.loading_spinner{
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
}

.loading_spinner span img{
    width: 40px;
    height: 40px;
}

@media screen and (max-width: 576px){
    .fa-facebook{
        right: 4%;
    }
}