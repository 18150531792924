.main-container{
    background-color: #cecece;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.game-start-header{
    font-size: 1.22em;
    text-align: center;
    color: #000;
    margin-bottom: 1rem;
    font-weight: 900;
    background-color: white;
    transition: box-shadow 0.3s; 
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}
.selected{
    width: 80px;
    height: 80px;
}
.play_content{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    padding: 20px;
    width: 60%;
}
.how-to-play-content{
    display: flex;
    flex-direction: column;
    padding: 5%;
    font-weight: 400;
    font-size: 1.2em;
    margin-top: 4rem;
    z-index: 10;
    background-color: white;
    border-radius: 20px;
    color:#787d85;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12); 
    transition: box-shadow 0.3s;
    width: 100%;
}
.how-to-play-content p span{
    font-weight: bold;
}
.bottom-ply-button{
    display: flex;
    justify-content: center;
    align-items: center;
}
.how-to-play-buttons{
    margin-bottom: 0.5rem;
    padding: 0;
    display: flex;
    justify-content:space-between;
    align-items: center;
    font-size: 0.92em;
    height: 55px;
    width: 270px;
    font-weight: bolder;
    background: -webkit-linear-gradient(to bottom, #FAA10D, #FB6558);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #FAA10D, #FB6558); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
    border-radius: 30px;
    cursor: pointer;
    border: none;
    text-transform: uppercase;
    letter-spacing: 1px; 
    box-shadow: 5px 10px 20px -8px #FB6558;
}
.play-now-button{
    margin-left: 4.3rem;
    font-size: 1.26rem;
    pointer-events: none;
}
.play-now-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    height: 45px;
    width: 45px;
    background: #FA6755;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #FA6755, #FA9B13);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #FA6755, #FA9B13);
    border-radius: 50%;
    font-size: 23px;
    pointer-events: none;
}
/* .how-to-play-buttons button:hover{
    transform: scale(1.2);
} */
.how-to-play-buttons button:focus{
    box-shadow: none;
    border: none;
    outline: none;
}
.game_level{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to left, #449fe7, #3dabec, #3db7f0, #43c3f2, #4fcef4);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    color: #ffff;
    margin-top: 5px;
}
.game_level_id{
    width: 25px;
    height: 25px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
}
.Instruction_list{
    margin-bottom: 10px;
    display: flex;
    flex-shrink: 0;
}
.game_instruction{
    margin-left: 10px;
    letter-spacing: 0.2px;
}
@media screen and (max-width: 576px){
.how-to-play-content{
        margin-top: 3rem;
        font-size: 0.89em;
        height: 65vh;
        overflow: auto;
       
}
.how-to-play-content-ios{
    margin-top: 3rem;
    font-size: 0.89em;
    overflow: scroll;
    height: 65vh;
}
.game-start-header-ios{
    margin: 28px 0px;
}
   /* .game-start-header{
        width: 90%;
    }*/
    .play_content{
        width: 100%;
    }
    .selected{
        width: 50px;
        height: 50px;
    }
}
