.react-countdown-container{
    border: 1px solid #cacaca;
    display: flex;
    justify-content: center;
    align-items: center;
}
.colon{
    font-size: 20px;
    color: #949494;
    margin-bottom: 5px;
}
.react-countdown-container li {
    display: inline-block;
    font-size: 7px;
    text-align: center;
    padding: 1px 5px;
    width: 40px;
    background-color: white;
    color: #949494;
    font-weight: 600;
    opacity: 1;
    list-style-type: none;
    text-transform: capitalize;
}

.danger-time li{
    background-color: #FF6347 !important;
}

.react-countdown-container li span {
    font-weight: 400px;
    display: block;
    font-size: 15px;
    font-weight: 700;
}
.expired-time{
    width: 200px;
    padding: 5px 10px;
    margin: 0px auto;
    background-color: darkblue;
    color: white;
    letter-spacing: 2px;
}

@media screen and (max-width:576px){
    .react-countdown-container li {
        width: 26px;
        font-size: 5px;
    }
    .counter-wrapper{
        width: 135px;
    }
}

@media screen and (max-width:320px){
    .counter-wrapper {
        width: 120px;
    }
    
}
/*style for banner timer*/
.react-countdown-conatiner-slider-banner{
   
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5%;
    box-shadow:1px 7px 20px -9px hsl(0, 0%, 53%);
    height: 39px;
}
.react-countdown-conatiner-slider-banner li{
    display: inline-block;
    font-size: 0.4em;
    text-align: center;
    border-right: 1px solid;
    padding: 1px 5px;
    background-color: white;
    font-weight: 600;
    opacity: 1;
    list-style-type: none;
    text-transform: capitalize;
}
.react-countdown-conatiner-slider-banner .last-li{
    border-right: none;
}
.react-countdown-conatiner-slider-banner li span{
    display: block;
    font-size: 2.5em;
    color:#333;
    font-weight: bolder;
    
}
.ractangle {
    padding: 0 5px;
    font-size: 12px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: none;
    font-weight: bolder;
    width: 60px;
    height: 40px;
    background: linear-gradient(to left, #449fe7, #3dabec, #3db7f0, #43c3f2, #4fcef4);
    clip-path: polygon(0 0, 81% 0, 100% 50%, 81% 100%, 0 100%);
}
