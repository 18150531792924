.gameplay-top{
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}
.gameplay-inst{
    display: flex;
    justify-content: space-between; 
    width: 100%;
    margin-top: 30px;
}

.inst-image{
    display: flex;
    justify-content: center;
    width: 30%;
    border-top-right-radius: 12px;
    background-color: white;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12); 
    align-items: center;
    padding: 5px 20px;
    position: relative;
}

.inst-image img{
    width: 60px !important;
    height: 60px !important;
    object-fit: contain;
}

.inst-image div:nth-child(2){
    color: slategrey;
    font-weight: 500;
    font-size: 12px;
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
}

.ticker-inst{
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0px;
    left: 0px;
    align-items: center;
    font-size: 14px;
    padding: 2px 5px;
    border-radius: 4px;
    color: white;
    background-image: linear-gradient(to bottom, #87c6e7, #6db7e2, #53a8dd, #3699d8, #088ad2);
    display: flex;
    align-items: center;
    justify-content: center;

}


@media screen and (max-width: 767px){
    .gameplay-top{
        height: 150px;
        margin-bottom: 0rem;
    }
    .gameplay-inst{
        width: 100%;
        height: 100%; 
        align-items: center;
        margin-top: 0px;

    }
    .inst-image{
        width: 6em;
        height: 6em;
        border-radius: 80px;
        flex-direction: column;
        margin: 5px;
        align-items: center;
        justify-content: center;
        padding: 0%;
    }
    .inst-image img{
        width: 35px !important;
        height: 35px !important;
    }
    .ticker-inst{
        top: 5px;
        left: 10px;
        width: 15px;
        height: 15px;
    }
}