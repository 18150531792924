.loading-container-main{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 150px;
}
.loading-container-inner{
    width: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: white;
}
.loading-cont-header{
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 10px;
    padding: 5px 0;
    color: #F37335;
    font-weight: 700;
    border-bottom: 2px solid #dadada;
}
.loading-screen-custom{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
}

.loader-text-custom{
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: gray;
}

@media screen and (max-width: 576px){
    .loading-container-main{
        margin-top: 4.5rem;
    }
    .loading-container-inner{
        width: 90%;
    }
}