.faq-heading-custom{
    font-size: 20px;
    padding: 10px 12px;
    width: 100%;
    text-align: center;
    margin-top: 5rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #F37335;
    font-weight: 700;
}
.faq-main{
    margin-bottom: 1rem;
}
.faq-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 3rem;
    padding: 0.5rem 0.5rem;
}

.faq-container .faq-question{
    font-weight: bold;
    text-align: justify;
    margin-bottom: 0.5rem;
}

.faq-answer{
    text-align: justify;
}

@media screen and (max-width: 576px){
    .faq-container{
        font-size: 14px;
    }
}