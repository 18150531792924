.toolbar{
    width: 100%;
    background: #E44D26;
    background: linear-gradient(to right, #EC4E3B, #FA9B13);
    height: 58px;
    position: fixed;
    border: none;
    outline: none;
    box-shadow: none;
    top: 0;
    left: 0;
    z-index: 100;
}
.rounded-header-bottom{
    display: none;
}

.toolbar-toggle-button{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 20px;
    width: 18px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
}

.toolbar-toggle-button:focus{
    outline: none;
}

.toolbar-toogle-button-line{
    width: 18px;
    height: 2px;
    background: white;
}

.toolbar-logo {
    cursor: pointer;
    margin-left: 0.3rem;
}

.toolbar-navigation{
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0.5rem 1rem;
}

.toolbar-space{
    flex: 1;
}

.toolbar-navigation-items ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}
.back-button{
    width: 100px;
    height: 35px;
    border-radius: 20px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
}
a {
    color:black;
    text-decoration: none;
    background-color: transparent;
}
a:hover{
    color: black;
    text-decoration: none;
}
.toolbar-navigation-items li{
    padding: 0px 6px;
}
.header-icon{
    color: white;
    margin-right: 0.5rem;
    margin-top: 5px;
    pointer-events: none;
}
@media screen and (max-width: 576px){
    .rounded-header-bottom{
        display: block;
        height: 50px;
        width: 100%;
        outline: none;
        border: none;
        box-shadow: none;
        position: fixed;
        background: #E44D26;
        background: linear-gradient(to right, #EC4E3B, #FA9B13);
        top: 58px;
        border-bottom-left-radius: 600px;
        border-bottom-right-radius: 600px;
    }
    .header-leaderboard{
        height: 380px;
        border-bottom-left-radius: 60px;
        border-bottom-right-radius: 60px;

    }
    .toolbar-logo {
        margin-left: 30%;
    }
}

@media screen and (max-width: 321px){
    .toolbar-logo {
        margin-left: 24%;
    }
}

