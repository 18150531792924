.profile-heading-custom{
    font-size: 20px;
    padding: 10px 12px;
    width: 100%;
    text-align: center;
    margin-top: 5rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #F37335;
    font-weight: 700;
}

.has-search3 .form-control {
    padding-left: 2.375rem;
    border-top: none;
    outline: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-radius: 0px;
}

.form-control:focus{
    outline: none;
    border-bottom: 2px solid #F37335;
    box-shadow: none !important;
}

.form-control-feedback3 {
    position: absolute;
    z-index: 2;
    display: block;
    width: 20px;
    height: 20px;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    text-align: center;
    pointer-events: none;
    color: #F37335;
}

.custom-profile-btn-class{
    background-color: #F37335 !important;
    opacity: 0.9 !important;
    border: none !important;
    outline: none !important;
    color: white !important;
    letter-spacing: 2px !important;
    text-transform: uppercase !important;
    border-radius: 30px;
    padding: 8px 10px !important;
    opacity: 0.8;
}

.custom-profile-btn-class:hover{
    background-color: #F37335 !important;
    opacity: 1 !important;
}

.custom-profile-btn-class:focus{
    box-shadow: none !important;
}

.custom-profile-btn-class:active{
    box-shadow: 0 1px 0 #F37335 !important;
    top: 1px !important;
}

.profile-image-custom{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.profile-image-custom img{
    width: 200px;
    height: 200px;
    border-radius: 50%;
}

.uploading-icon{
    color: #F37335;
    margin-top: 0.5rem;
    font-size: 24px;
}
.unsubText{
    color: #4a4a4a;
    text-align: center;
    font-size: 14px;
    font-style: italic;
    margin-top: 2%;
}
@media screen and (max-width: 576px){
    .profile-container{
        width: 90%;
        margin-top: 5rem;
    }
   
}
@media screen and (max-width: 340px){
    .profile-image-custom img{
        width: 150px;
        height: 150px;
    }
}

.custom-progress{
    width: 200px;
    font-size: 10px;
}

.user-details{
    display: flex;
    border: none;
    border: 1px solid white;
    background-color: whitesmoke;
    border-radius: 5px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 10px;
    margin-left: 2rem;
    font-size: 14px;
    transition: 0.5s;
    margin-bottom: 0.5rem;
}

.user-details div{
    display: flex;
    flex-direction: column;
}

.user-details div span:nth-child(1){
    font-weight: 500;
    font-style: normal;
}

.user-details div span:nth-child(2){
    font-weight: 600;
    font-style: normal;
}

.show-user-details{
    color: #F37335;
    display: flex;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
}

.show-user-details span:nth-child(1){
    font-size: 16px;
    margin-right: 10px;
    pointer-events: none;
}

.show-user-details span:nth-child(2){
    font-weight: bold;
    font-size: 16px;
    vertical-align: bottom;
    margin-right: 20px;
    pointer-events: none;
}

.show-user-details span:nth-child(3){
    font-size: 16px;
    font-weight: bold;
    pointer-events: none;
}