.questions{
    display: block;
    font-size: 16px;
    text-align: center;
    word-wrap: break-word;
    font-weight: 600;
    margin-top: 0rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
}

.image-gallery{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.image-gallery .image-container{
    position: relative;
    top: 0;
    left: 0;
}

.tick-image{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.6;
    z-index: -1;
    transition: z-index all 0.1s;
}

.ticked{
    z-index: 1 !important;
}

.image-gallery img{
    pointer-events: none;
    width: 100px;
    height: 100px;
    border: 1px solid white;
    object-fit: fill;
}

.shake{
    animation: shake 0.6s;
}

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.game-loader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 280px;
    width: 100%;
}

.scoreboard-quiz{
    display: block;
    width: 100%;
    max-width: 270px;
    text-align: center;
    font-size: 18px;
    color: #eb5a3e;
    letter-spacing: 3px;
    font-weight: 900;
}

.scoreboard-quiz span{
    text-transform: uppercase;
    color: #eb5a3e;
    padding: 1px 3px;
    font-size: 16px;
    font-weight: 700;
    margin: 0px 5px;
}

@media screen and (max-width: 576px){
    .scoreboard-quiz{
        font-size: 12px;
    }
    .scoreboard-quiz span{
        font-size: 14px;
    }
    .image-gallery img{
        pointer-events: none;
        width: 80px;
        height: 80px;
        border: 1px solid white;
        object-fit: fill;
    }
}

@media screen and (max-width: 321px){
    .image-gallery img{
        pointer-events: none;
        width: 60px;
        height: 60px;
        border: 1px solid white;
        object-fit: fill;
    }
}

.custom-progress-bar{
    display: flex !important;
    justify-content: flex-start !important;
    margin-top: 1rem;
    width: 100%;
    max-width: 300px;
    padding: 0 0;
    border-radius: 20px;
    background-color: rgba(255,69,0,0.2);
}

.custom-progress-bar span{
    border-radius: 20px;
    border: 1px solid #eb5a3e;
}

.disable-click{
    pointer-events: none;
}

.image-changing-game{
    height: 400px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.image-changing-game div{
    margin: 0 20px;
    color: rgb(48,135,194);
    font-weight: bold;
    text-align: center;
    font-size: 1rem;
    letter-spacing: 1px;
}

@media screen and (max-width: 576px){
    .image-changing-game{
        height: 320px;
        width: 240px;
    }
    .custom-progress-bar{
        max-width: 240px;
    }
}

@media screen and (max-width: 321px){
    .image-changing-game{
        height: 240px;
        width: 180px;
    }
    .custom-progress-bar{
        max-width: 180px;
    }
}

.gameplay-error{
    position: absolute;
    top: -25px;
    background-color:  #95772e;
    color: white;
    border: none;
    outline: none;
    border-radius: 60%;
    padding: 10px 10px;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    z-index: 10;
    box-shadow: 0 0 8px 2px #95772e;
}

.gameplay-error div:nth-child(2){
    font-size: 12px
}

.gameplay-error div:nth-child(3){
    font-size: 12px
}

.hideattempt{
    visibility: hidden;
}

.timer-container-game{
    margin-top: 2.8rem;
}