.main-leaderboard-container {
    background-color: #cecece;
    min-height: 100vh;
    display: flex;
    justify-content: center;
}

.leaderboard-container {
    margin-top: 85px;
    width: 55%;
    z-index: 10;
}

.top-10-text {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    color: #777;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.top-10-header {
    background-color:white;
    box-shadow: -1px 6px 9px -1px rgba(0, 0, 0, 0.38);
    transition: box-shadow 0.3s;
    border-radius: 20px;
}
.img-content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
}

.top-10-header-image {
    padding: 10px;
    border-radius: 50%;
    margin-right: 1rem;
}

.top-10-header-image img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    object-fit: cover;
}

.top-10-header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.header-top-title {
    font-size: 0.9rem;
    color: #333;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
}

.best-score-title {
    font-size: 0.9rem;
    font-weight: bolder;
}

.best-score-title span:nth-child(1) {
    color: #fa882b;
}

.best-score-title span:nth-child(2) {
    color: black;
    padding-left: 5px;
}

.best-rank-title {
    font-size: 0.9rem;
    font-weight: bolder;
    text-transform: capitalize;
    letter-spacing: 1px;
}

.best-rank-title span:nth-child(1) {
    color: green;
}

.best-rank-title span:nth-child(2) {
    color: black;
    padding-left: 5px;
}

.top-10-list-main {
    margin-top: 1.5rem;
    border-radius: 20px;
    background-color:white;
    box-shadow: -1px 6px 9px -1px rgba(0, 0, 0, 0.38);
    transition: box-shadow 0.3s;
    margin-bottom: 1.5rem;
}

.top-10-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    border-bottom: 2px solid #dadada;
    padding: 17px 25px 10px 25px;
}

.top-10-list-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 120px;
}

.top-10-id {
    color:#555;
    font-weight: 900;
    font-size: 1.2rem;
    border-radius: 10px;
    width: 30px;
    text-align: center;
}

.top-10-car-img img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.top-10-card-name {
    color: #2b2b2b;
    font-weight: 900;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 1rem;
    margin-left: 5px;
}

.top-10-card-name span:nth-child(2){
    font-size: 0.9rem;
    color: #777;
    font-weight: 500;
}

.top-10-heading-text{
    text-align: center;
    font-size: 1.3rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fa882b;
    font-weight: bold;
    padding: 15px 0px 10px 0px;
    border-bottom: 2px solid #d9d9d9;
}

.top-10-time {
    color: #2b2b2b;
    font-weight: 900;
    font-size: 1.2rem;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.leader-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    cursor: pointer;
}

.leader-button span {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #faa10d;
    background: -webkit-linear-gradient(to bottom,
            #faa10d,
            #fb6558);
    background: linear-gradient(to bottom,
            #faa10d,
            #fb6558);
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 1px;
    border-radius: 30px;
    min-width: 280px;
    height: 55px;
    pointer-events: none;
}

@media screen and (max-width: 1200px) {
    .top-10-list-card {
        width: 30%;
    }
}

@media screen and (max-width: 800px) {
    .top-10-list-card {
        width: 41%;
    }
}

@media screen and (max-width: 600px) {
    .top-10-list-card {
        width: 53%;
    }
    .top-10-header{
        margin-top: 1rem;
    }
}

@media screen and (max-width: 576px) {
    .leaderboard-container {
        width: 90%;
       margin-top: 60px;
    }

    .top-10-list-card {
        width: 45%;
    }
    .top-10-car-img img {
        width: 35px;
        height: 35px;
    }
    .top-10-time{
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 320px) {
    .top-10-list-card {
        width: 53%;
    }
}