.slick-slide, .slick-slide *{ outline: none !important; }

.carousel-card-wrapper{
    background-color: white;
    height: 240px;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    position: relative;
}
.carousel-card-main{
    display: flex;
    padding: 10px 20px;
    height: 220px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.carousel-content-img{
    width: 25%;
    height: 160px;
    margin-right: 1.5rem;
}
.carousel-content-img img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.carousel-content-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-transform: uppercase;   
}
.carousel-title{
    color: #3c434e;
    font-size: 1.3em;
    font-weight: bolder;
    width: 100%;
}
.carousel-score-to-win{
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
  color:#fe7f3b;
  font-weight: bolder;
}
.carousel-score-to-win .part-a-score{
    margin-right: 4px;
}
.carousel-score-to-win .part-b-score{
    font-size: 16px;
    font-weight: bolder;
    color:#373e4a !important;
}
.carousel-counter{
    margin-top: 7px;
}
.carousel-button{
    display: flex;
    justify-content: space-between;
    align-items: center;    
    font-size: 0.87em;
    min-width: 150px;
    height: 40px;
    margin-top: 10px;
    font-weight: bolder;
    background: #FAA10D;  
    background: -webkit-linear-gradient(to bottom, #FAA10D, #FB6558);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #FAA10D, #FB6558); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 1px;
    box-shadow: 5px 10px 15px -8px #888888;
}
.play-icon{
    border-radius: 50%;
    color: white;
    padding-left: 5px;
    background: #FA6755;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #FA6755, #FA9B13);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #FA6755, #FA9B13); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    font-size: 23px;
    pointer-events: none;
    margin-right: 7px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}
.play-now{
    margin-left: 27px;
    pointer-events: none;
}
.slick-dots li button {
    font-size: 13px !important;
}
.slick-dots li.slick-active button:before {
    opacity: .75;
    color:#fd7e3b !important;
}
.slick-dots li button:before {
    font-size: 8px !important;
}
.slick-dots ul{
   
    margin-bottom: 1.2rem !important;
}
@media screen and (max-width: 840px) {
    .carousel-content-text{
       align-items: flex-start;
    }
    .slick-dots li{
        margin: 0 !important;
        width: 14px !important;
    }
    .slick-dots ul {
        margin-bottom: 0.9rem !important;
    }
    .carousel-content-img{
        width: 30%;
        height: 130px;
    }

}
@media screen and (max-width: 576px){
    .carousel-content-img{
        width: 40%;
        height: 110px;
        margin-right: 0.6rem;
    }  
    .carousel-card-wrapper{
        height: 198px;
    }
    .carousel-card-main{
        height: 180px;
    }
    .slick-dots li{
        margin: 0 !important;
        width: 18px !important;
    }
    .carousel-content-text{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;  
    }
    .carousel-title{
        width: 44vw;
        white-space: nowrap;
        overflow: hidden;
        font-size: 0.75em;
        text-overflow: ellipsis;
    }
    .carousel-score-to-win{
        margin-top: 5px;
        font-size: 11px;
    }
    .carousel-score-to-win .part-b-score{
        font-size: 12px;
    }
}

@media screen and (max-width:320px){
    .carousel-content-img {
        width: 25%;
        height: 70px;
        margin-right: 1.6rem;
       
    }
    .carousel-content-img img {
        width: 80px;
        height: 84px;
        border-radius: 5px;
        object-fit: fill;
         
     }
}