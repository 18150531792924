.custom-play-button {
   
    display: flex;
    justify-content: space-between;
    align-items: center;    
    font-size: 0.87em;
    min-width: 190px;
    height: 43px;
    font-weight: bolder;
    background: #FAA10D;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #FAA10D, #FB6558);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #FAA10D, #FB6558); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
    border-radius: 30px;
    border: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 0px;
    box-shadow: 5px 10px 15px -8px #888888;
    pointer-events: none;
}
    

.ArrowIcon{
    border-radius: 50%;
    color: white;
    padding-left: 5px;
    background: #FA6755;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #FA6755, #FA9B13);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #FA6755, #FA9B13); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    font-size: 23px;
    pointer-events: none;
    margin-right: 7px;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}
.play{
    margin-left: 27px;
    pointer-events: none;
}
@media screen and (max-width: 576px){
    .custom-play-button {
        min-width: 140px;
        height: 37px;
        font-size: 0.75rem;
       }
       .play {
        margin-left: 13px;
       }
       .ArrowIcon{
           margin-right: 5px;
           height: 30px;
        width: 30px;
       }

}
@media screen and (max-width:320px){

    .custom-play-button {
        min-width: 130px;
        font-size: 0.65rem;
        
       }
}



