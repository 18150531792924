
.noscore-heading-customs{
    display: flex;
    justify-content: center;
}
.no-score-card{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 700;
    margin: 2rem 0rem;
}
.myscore-main-container{
    background-color: white;
    min-height:100vh;
}
.myscore-custom-card{
    display: flex;
    justify-content: center;
    align-items: center;
}
.top-score-custom-card{
    margin-top: 6rem;
    background-color: white;
    z-index: 10;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: box-shadow 0.3s;
    margin-bottom: 1rem;
    width: 50%;
}
.myscore-header{
    font-size: 20px;
    color: #F37335;
    font-weight: bolder;
    text-align: center;
    padding: 10px 0px 7px 0px;
    border-bottom: 1px solid #d9d9d9;
    text-transform: uppercase;
    letter-spacing: 2px;
    
}
.custom-score-card{
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid #d9d9d9;
    padding: 15px 5px 7px 5px;
}
.product-image img {
    width: 150px;
    height: 100px;
    margin-left: 20px;
    object-fit: contain;
}
.details{
    width: 220px;
    height: auto;
    margin-left:70px;
}
.custom-product-name{
    font-weight: 700;
}
.your-score{
    margin-top: 5px;
    font-weight:bolder;
}
.your-score span:nth-child(1){
    color: #F37335;
}
.your-score span:nth-child(2){
    color: #3b3b3b;
    margin-left: 10px;
}
.myscore-button{
    background: -webkit-linear-gradient(to bottom, #FAA10D, #FB6558);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #FAA10D, #FB6558); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
    border-radius: 30px;
    margin-top: 5px;
    width: 150px;
    height: 36px;
    display: flex;
    align-items: center;
    font-weight: 700;
    justify-content: space-around;
    cursor: pointer;
}
.top-10-custom-buttons{
    margin-left: 14px;
}
.top-10-custom-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FA6755;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #FA6755, #FA9B13);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #FA6755, #FA9B13);
    border-radius: 50%;
    margin-left: 9px;
    width: 27px;
    height: 27px;
  
}
@media screen and (max-width:1200px){
    .details{
       margin-left:25px;
    }
}

@media screen and (max-width:600px){
    .top-score-custom-card {
        width: 90%;
        margin-top: 4rem;
    }
    .details{
       margin-left:20px;
    }
    .product-image img {
        width: 110px;
        height: 80px;
    }
    
}
@media screen and (max-width:320px){
    .myscore-button {
        width: 130px;
        height: 32px;
        font-size: 0.9em;      
    }
    .details {
        font-size: 14px;
        margin-left:10px;
    }
}
