.games-main{
    background-color: #cecece;
    display: flex;
    justify-content: center;
    min-height: 100vh;
}
.games-container{
    width: 100%;
}
.game-screen{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    position: relative;
    padding: 0rem;
}

.game-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12); 
    padding: 10px 30px;
    margin: 1rem 2rem;
    width: 450px;
    transition: box-shadow .25s;
    position: relative;
}

.game-card:hover{
    box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
}

.level-indicator{
    position: absolute;
    top: -8px;
    background: rgb(48,135,194);
    background: linear-gradient(90deg, rgba(48,135,194,1) 0%, rgba(78,204,247,1) 100%);
    color: white;
    border: none;
    outline: none;
    border-radius: 60%;
    padding: 12px 10px;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    z-index: 10;
}

.gameplay-error-overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    background-color: slategray;
    z-index: -5;
    opacity: 0.75;
    visibility: hidden;
    pointer-events: none;
}

.gameplay-error-overlay span{
    font-size: 40px;
    color: white;
    letter-spacing: 5px;
    font-weight: bolder !important;
    opacity: 1 !important;
    display: block;
    z-index: 20 !important;
}

.show-overlay{
    visibility: visible !important;
    z-index: 5;
}