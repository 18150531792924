.custom-button{
    position: relative;
}

.custom-button button{
    width: 100%;
    background-color: white !important;
    border: none !important;
    outline: none !important;
    color: #373E4A !important;
    letter-spacing: 2px !important;
    border-radius: 30px;
    padding: 7px 10px;
    font-weight: bold;
    font-size: 16px;
    box-shadow: 2px 2px 2px orange;
}

.custom-button button:focus{
    box-shadow: none !important;
}

.custom-button button:active{
    box-shadow: 0 1px 0  white !important;
    top: 1px !important;
}

.custom-button span{
    position: absolute;
    float: right;
    text-align: right;
    right: 1%;
    top: 4px;
    border-radius: 50%;
    color: white;
    padding: 2px 11px;
    background: #FAA10D;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #FAA10D, #FB6558);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #FAA10D, #FB6558); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    font-size: 16px;
    pointer-events: none;
}

@media screen and (max-width: 767px){
    .custom-button span{
        right: 3%;
    }
}