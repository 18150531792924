.splash_page{
    position: fixed !important;
    min-width: 100% !important;
    min-height: 100% !important;
    z-index: -10;
    top: 0;
    left: 0;
    background: #fc4a1a;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #f7b733, #fc4a1a);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #f7b733, #fc4a1a); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.splash-screen-main{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.splash-logo{
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.splash-logo img{
  width: auto;
  height: 100%;
  object-fit: cover;
}

.splash-loader{
  font-size: 32px;
  color: white;
  margin-top: 3rem;
}