
.main-home-container{
    display: flex;
    min-height: 100vh;
}
.home-page{
    margin-top: 5rem;
}
.contest-cards{
    max-width: 1200px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    gap: 1rem;
    margin-bottom: 1.5rem;
}
@media screen and (max-width: 576px){
    .home-page{
        margin-top: 4rem;
        padding-left: 20px;
        padding-right: 20px;
    }
    .contest-cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
    }
}