.timer div{
    display: inline-flex;
    font-size: 1.5rem;
    border-radius: 2px;
    padding: 0px 2px;
    font-weight: bold;
    letter-spacing: 2px;
}

.warning-timer{
    color: #e7491a;
}

.success-timer{
    color: #479dd2;
}