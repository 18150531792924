@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
html{
  overflow-y: auto !important;
}

body {
  margin: 0;
}

::placeholder { /* Firefox, Chrome, Opera */ 
  color: gray !important;
  opacity: 0.5 !important;
} 

:-ms-input-placeholder { /* Internet Explorer 10-11 */ 
  color: gray !important;
  opacity: 0.5 !important;
} 

::-ms-input-placeholder { /* Microsoft Edge */ 
  color: gray !important;
  opacity: 0.5 !important;
}
